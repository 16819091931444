import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCandidate, getCompanyEmployees, getColleagues, getRankedColleagues, compareColleaguesWithConnections, getPersonContactInfo, getGithubCollaborators, getVettingCreditBalance, getVettingCreditsPricing, createVettingCreditPayment, confirmVettingCreditPayment } from './api';
import api from './api';


export const fetchCandidate = createAsyncThunk(
  'vet/fetchCandidate',
  async (linkedinId, { rejectWithValue }) => {
    try {
      const response = await getCandidate(linkedinId);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchCompanyEmployees = createAsyncThunk(
  'vet/fetchCompanyEmployees',
  async (vertex, { rejectWithValue }) => {
    try {
      const response = await getCompanyEmployees(vertex);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchColleagues = createAsyncThunk(
  'vet/fetchColleagues',
  async ({ aviatoIds, mainCandidateId }, { rejectWithValue }) => {
    try {
      const response = await getColleagues(aviatoIds, mainCandidateId);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchRankedColleagues = createAsyncThunk(
  'vet/fetchRankedColleagues',
  async (aviatoId, { rejectWithValue }) => {
    try {
      const response = await getRankedColleagues(aviatoId);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const compareWithConnections = createAsyncThunk(
  'vet/compareWithConnections',
  async (colleagues, { rejectWithValue }) => {
    try {
      const response = await compareColleaguesWithConnections(colleagues);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchPersonContactInfo = createAsyncThunk(
  'vet/fetchPersonContactInfo',
  async (aviatoId, { rejectWithValue }) => {
    try {
      const response = await getPersonContactInfo(aviatoId);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchGithubCollaborators = createAsyncThunk(
  'vet/fetchGithubCollaborators',
  async (github_username, { rejectWithValue }) => {
    try {
      const response = await getGithubCollaborators(github_username);
      return response;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchVettingCreditBalance = createAsyncThunk(
  'vet/fetchVettingCreditBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getVettingCreditBalance();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to fetch vetting credit balance');
    }
  }
);

export const fetchVettingCreditsPricing = createAsyncThunk(
  'vet/fetchVettingCreditsPricing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getVettingCreditsPricing();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to fetch vetting credits pricing');
    }
  }
);

export const initiateVettingCreditPurchase = createAsyncThunk(
  'vet/initiateVettingCreditPurchase',
  async (numCredits, { rejectWithValue }) => {
    try {
      const response = await createVettingCreditPayment(numCredits);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to initiate vetting credit purchase');
    }
  }
);

export const completeVettingCreditPurchase = createAsyncThunk(
  'vet/completeVettingCreditPurchase',
  async (paymentIntentId, { rejectWithValue }) => {
    try {
      const response = await confirmVettingCreditPayment(paymentIntentId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to complete vetting credit purchase');
    }
  }
);

export const uploadLinkedinConnections = createAsyncThunk(
  'vet/uploadLinkedinConnections',
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      const response = await api.post('/linkedin/upload-connections', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to upload LinkedIn connections');
    }
  }
);

export const processResumeExperience = createAsyncThunk(
  'vet/processResumeExperience',
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('resume', file);
      
      const response = await api.post('/resume/extract-experience', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to process resume');
    }
  }
);

export const sendReferenceEmail = createAsyncThunk(
  'vet/sendReferenceEmail',
  async ({ name, email, candidateId, referenceId }, { rejectWithValue }) => {
    try {
      const response = await api.post('/email/send-template-email', {
        name: name || 'Reference',
        email,
        template: 'reference_request',
        data: {
          candidate: 'the candidate',
          previous_job: 'GitHub projects',
          new_role: 'the position',
          candidate_id: candidateId,
          reference_id: referenceId
        }
      });
      
      return { 
        success: response.data.success, 
        referenceId 
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to send reference email');
    }
  }
);

const initialState = {
  candidate: null,
  colleagues: [],
  rankedColleagues: [],
  connectionMatches: [],
  nonConnectionMatches: [],
  companyEmployees: [],
  contactInfo: {},
  contactInfoLoading: {},
  contactInfoError: {},
  loading: false,
  error: null,
  success: false,
  comparisonDone: false,
  githubCollaborators: [],
  githubLoading: false,
  githubError: null,
  vettingCredits: 0,
  vettingCreditsPricing: null,
  vettingCreditPayment: null,
  vettingCreditPurchaseStatus: 'idle',
  vettingCreditPurchaseError: null,
  uploadingConnections: false,
  uploadConnectionsError: null,
  uploadConnectionsSuccess: false,
  processingResume: false,
  resumeExperience: [],
  resumeError: null,
  emailSending: {},
  emailSent: {},
  emailError: {}
};

const vetSlice = createSlice({
  name: 'vet',
  initialState,
  reducers: {
    clearVetState: (state) => {
      state.candidate = null;
      state.colleagues = [];
      state.rankedColleagues = [];
      state.connectionMatches = [];
      state.nonConnectionMatches = [];
      state.companyEmployees = [];
      state.contactInfo = {};
      state.contactInfoLoading = {};
      state.contactInfoError = {};
      state.loading = false;
      state.error = null;
      state.success = false;
      state.comparisonDone = false;
      state.githubCollaborators = [];
      state.githubLoading = false;
      state.githubError = null;
    },
    clearVettingCreditPurchaseState: (state) => {
      state.vettingCreditPayment = null;
      state.vettingCreditPurchaseStatus = 'idle';
      state.vettingCreditPurchaseError = null;
    },
    clearConnectionsUploadState: (state) => {
      state.uploadingConnections = false;
      state.uploadConnectionsError = null;
      state.uploadConnectionsSuccess = false;
    },
    clearResumeState: (state) => {
      state.processingResume = false;
      state.resumeExperience = [];
      state.resumeError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.candidate = action.payload.candidate;
        state.colleagues = action.payload.candidate.colleagues || [];
      })
      .addCase(fetchCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch candidate';
      })
      
      .addCase(fetchCompanyEmployees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.companyEmployees = action.payload.employees || [];
      })
      .addCase(fetchCompanyEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch company employees';
      })
      
      .addCase(fetchColleagues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchColleagues.fulfilled, (state, action) => {
        state.loading = false;
        state.colleagues = action.payload.colleagues || [];
      })
      .addCase(fetchColleagues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch colleagues';
      })
      
      .addCase(fetchRankedColleagues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRankedColleagues.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Ranked colleagues action payload:", action.payload);
        state.rankedColleagues = action.payload.colleagues || [];
        // Reset comparison results when new ranked colleagues are fetched
        state.connectionMatches = [];
        state.nonConnectionMatches = [];
        state.comparisonDone = false;
      })
      .addCase(fetchRankedColleagues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch ranked colleagues';
      })
      
      .addCase(compareWithConnections.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(compareWithConnections.fulfilled, (state, action) => {
        state.loading = false;
        state.connectionMatches = action.payload.matches || [];
        state.nonConnectionMatches = action.payload.non_matches || [];
        state.comparisonDone = true;
      })
      .addCase(compareWithConnections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to compare with connections';
      })
      
      .addCase(fetchPersonContactInfo.pending, (state, action) => {
        const aviatoId = action.meta.arg;
        state.contactInfoLoading = { 
          ...state.contactInfoLoading, 
          [aviatoId]: true 
        };
        state.contactInfoError = { 
          ...state.contactInfoError, 
          [aviatoId]: null 
        };
      })
      .addCase(fetchPersonContactInfo.fulfilled, (state, action) => {
        const aviatoId = action.meta.arg;
        state.contactInfoLoading = { 
          ...state.contactInfoLoading, 
          [aviatoId]: false 
        };
        state.contactInfo = { 
          ...state.contactInfo, 
          [aviatoId]: action.payload.contact_info 
        };
      })
      .addCase(fetchPersonContactInfo.rejected, (state, action) => {
        const aviatoId = action.meta.arg;
        state.contactInfoLoading = { 
          ...state.contactInfoLoading, 
          [aviatoId]: false 
        };
        state.contactInfoError = { 
          ...state.contactInfoError, 
          [aviatoId]: action.payload || 'Failed to fetch contact information'
        };
      })
      
      .addCase(fetchGithubCollaborators.pending, (state) => {
        state.githubLoading = true;
        state.githubError = null;
      })
      .addCase(fetchGithubCollaborators.fulfilled, (state, action) => {
        state.githubLoading = false;
        state.githubCollaborators = action.payload.collaborators || [];
      })
      .addCase(fetchGithubCollaborators.rejected, (state, action) => {
        state.githubLoading = false;
        state.githubError = action.payload || 'Failed to fetch GitHub collaborators';
      })
      
      .addCase(fetchVettingCreditBalance.fulfilled, (state, action) => {
        state.vettingCredits = action.payload.vetting_credits;
      })
      
      .addCase(fetchVettingCreditsPricing.fulfilled, (state, action) => {
        state.vettingCreditsPricing = action.payload;
      })
      
      .addCase(initiateVettingCreditPurchase.pending, (state) => {
        state.vettingCreditPurchaseStatus = 'loading';
      })
      .addCase(initiateVettingCreditPurchase.fulfilled, (state, action) => {
        state.vettingCreditPayment = action.payload;
        state.vettingCreditPurchaseStatus = 'succeeded';
      })
      .addCase(initiateVettingCreditPurchase.rejected, (state, action) => {
        state.vettingCreditPurchaseStatus = 'failed';
        state.vettingCreditPurchaseError = action.payload;
      })
      
      .addCase(completeVettingCreditPurchase.pending, (state) => {
        state.vettingCreditPurchaseStatus = 'loading';
      })
      .addCase(completeVettingCreditPurchase.fulfilled, (state, action) => {
        state.vettingCredits = action.payload.current_vetting_credits;
        state.vettingCreditPayment = null;
        state.vettingCreditPurchaseStatus = 'succeeded';
      })
      .addCase(completeVettingCreditPurchase.rejected, (state, action) => {
        state.vettingCreditPurchaseStatus = 'failed';
        state.vettingCreditPurchaseError = action.payload;
      })
      
      .addCase(uploadLinkedinConnections.pending, (state) => {
        state.uploadingConnections = true;
        state.uploadConnectionsError = null;
        state.uploadConnectionsSuccess = false;
      })
      .addCase(uploadLinkedinConnections.fulfilled, (state) => {
        state.uploadingConnections = false;
        state.uploadConnectionsSuccess = true;
      })
      .addCase(uploadLinkedinConnections.rejected, (state, action) => {
        state.uploadingConnections = false;
        state.uploadConnectionsError = action.payload || 'Failed to upload LinkedIn connections';
      })
      
      .addCase(processResumeExperience.pending, (state) => {
        state.processingResume = true;
        state.resumeError = null;
      })
      .addCase(processResumeExperience.fulfilled, (state, action) => {
        state.processingResume = false;
        state.resumeExperience = action.payload.experience?.experience || [];
      })
      .addCase(processResumeExperience.rejected, (state, action) => {
        state.processingResume = false;
        state.resumeError = action.payload || 'Failed to process resume';
      })
      
      .addCase(sendReferenceEmail.pending, (state, action) => {
        const referenceId = action.meta.arg.referenceId;
        state.emailSending = { ...state.emailSending, [referenceId]: true };
        state.emailError = { ...state.emailError, [referenceId]: null };
      })
      .addCase(sendReferenceEmail.fulfilled, (state, action) => {
        const referenceId = action.payload.referenceId;
        state.emailSending = { ...state.emailSending, [referenceId]: false };
        state.emailSent = { ...state.emailSent, [referenceId]: true };
      })
      .addCase(sendReferenceEmail.rejected, (state, action) => {
        const referenceId = action.meta.arg.referenceId;
        state.emailSending = { ...state.emailSending, [referenceId]: false };
        state.emailError = { ...state.emailError, [referenceId]: action.payload };
      });
  }
});

export const { clearVetState, clearVettingCreditPurchaseState, clearConnectionsUploadState, clearResumeState } = vetSlice.actions;
export default vetSlice.reducer;
