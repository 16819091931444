import React, { useState } from 'react';
import { 
  Card, CardContent, Typography, Button, 
  CircularProgress, Box, Chip, Divider, Link,
  Snackbar, Alert, Tooltip
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonContactInfo } from '../../services/vetSlice';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const ColleagueCard = ({ colleague, isConnection }) => {
  const dispatch = useDispatch();
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  // Get contact info, loading and error states from Redux
  const contactInfo = useSelector(state => 
    state.vet.contactInfo[colleague.id] || null
  );
  const loading = useSelector(state => 
    state.vet.contactInfoLoading[colleague.id] || false
  );
  const error = useSelector(state => 
    state.vet.contactInfoError[colleague.id] || null
  );

  const fetchContactInfo = () => {
    dispatch(fetchPersonContactInfo(colleague.id));
  };

  const handleReachOutToReference = async () => {
    if (!contactInfo || !contactInfo.emails || contactInfo.emails.length === 0) {
      setEmailError("No email address available. Please get contact info first.");
      setSnackbarOpen(true);
      return;
    }

    try {
      setEmailSending(true);
      setEmailError(null);

      // Generate unique IDs for candidate and reference
      const candidateId = `candidate_${Date.now()}`;
      const referenceId = `reference_${colleague.id}_${Date.now()}`;

      // Send email to reference
      const response = await axios.post(`${API_BASE_URL}/email/send-template-email`, {
        name: colleague.name || 'Reference',
        email: contactInfo.emails[0], // Use the first email
        template: 'reference_request',
        data: {
          candidate: 'the candidate', // Replace with actual candidate name if available
          previous_job: colleague.shared_company || 'their previous company',
          new_role: 'the position', // Replace with actual role if available
          candidate_id: candidateId,
          reference_id: referenceId
        }
      });

      if (response.data.success) {
        setEmailSent(true);
        setSnackbarOpen(true);
      } else {
        throw new Error(response.data.error || 'Failed to send email');
      }
    } catch (err) {
      console.error('Error sending reference email:', err);
      setEmailError(err.message || 'Failed to send email');
      setSnackbarOpen(true);
    } finally {
      setEmailSending(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Card sx={{ 
      mb: 2, 
      borderRadius: 2,
      border: isConnection ? '1px solid #4caf50' : 'inherit',
      boxShadow: isConnection ? '0 0 8px rgba(76, 175, 80, 0.3)' : 'inherit'
    }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Typography variant="h6">{colleague.name || 'Unknown'}</Typography>
          {isConnection && (
            <Chip 
              icon={<CheckCircleIcon />} 
              label="LinkedIn Connection" 
              color="success" 
              size="small"
              sx={{ ml: 1 }}
            />
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          Confidence: {(colleague.confidence * 100).toFixed(1)}%
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Worked together at {colleague.shared_company}
        </Typography>
        
        {colleague.linkedin_id && (
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 1 }}>
            <LinkedInIcon color="primary" fontSize="small" />
            <Link 
              href={`https://www.linkedin.com/in/${colleague.linkedin_id}`} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="hover"
            >
              {colleague.linkedin_id}
            </Link>
          </Typography>
        )}
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: 'primary.main' }}>
            Reference Actions:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={fetchContactInfo}
              disabled={loading || contactInfo !== null}
            >
              {loading ? <CircularProgress size={24} /> : 'Get Contact Info'}
            </Button>
            
            {/* <Button
              variant="contained"
              color="secondary"
              startIcon={<EmailIcon />}
              onClick={handleReachOutToReference}
              disabled={emailSending || emailSent || !contactInfo || !contactInfo.emails || contactInfo.emails.length === 0}
              sx={{ 
                fontWeight: 'bold',
                '&:not(:disabled)': {
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)'
                }
              }}
            >
              {emailSending ? (
                <CircularProgress size={24} color="inherit" />
              ) : emailSent ? (
                'Email Sent'
              ) : !contactInfo || !contactInfo.emails || contactInfo.emails.length === 0 ? (
                <Tooltip title="Get contact info first to enable this button">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>Reach Out to Reference</span>
                    <InfoIcon fontSize="small" sx={{ ml: 1 }} />
                  </Box>
                </Tooltip>
              ) : (
                'Reach Out to Reference'
              )}
            </Button> */}
          </Box>
        </Box>
        
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        
        {contactInfo && (
          <Box sx={{ mt: 2 }}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="subtitle2">Contact Information:</Typography>
            
            {contactInfo.emails && contactInfo.emails.length > 0 ? (
              <>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>
                  Emails:
                </Typography>
                {contactInfo.emails.map((email, index) => (
                  <Chip 
                    key={index} 
                    label={email} 
                    size="small" 
                    sx={{ mr: 1, mt: 0.5 }} 
                  />
                ))}
              </>
            ) : (
              <Typography variant="body2">No email addresses found</Typography>
            )}
            
            {contactInfo.phones && contactInfo.phones.length > 0 ? (
              <>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>
                  Phone Numbers:
                </Typography>
                {contactInfo.phones.map((phone, index) => (
                  <Chip 
                    key={index} 
                    label={phone} 
                    size="small" 
                    sx={{ mr: 1, mt: 0.5 }} 
                  />
                ))}
              </>
            ) : (
              <Typography variant="body2">No phone numbers found</Typography>
            )}
          </Box>
        )}
      </CardContent>
      
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={emailError ? "error" : "success"} 
          sx={{ width: '100%' }}
        >
          {emailError ? emailError : "Email sent successfully to reference!"}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ColleagueCard; 