// File: src/components/Auth/AuthPage.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid,
  Avatar,
  Container,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { login, signup, clearSignupSuccess } from '../../services/authSlice';
import GoogleAuthButton from './GoogleAuthButton';

import { styled } from '@mui/system';
import '@fontsource/plus-jakarta-sans';
import authBackground from '../../assets/auth_background.jpg'; 
import logo from '../../assets/logo-white.png';
import { colors } from '../common/IntratalentTheme';
import { motion } from 'framer-motion';

const AuthContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  backgroundColor: colors.black1,
}));

const LeftPanel = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${authBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide left panel on smaller screens for a cleaner layout
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BrandingBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: colors.white,
}));

const RightPanel = styled(Grid)(({ theme }) => ({
  backgroundColor: colors.black1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const FormWrapper = styled(motion.div)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  padding: theme.spacing(4),
  borderRadius: '20px',
  border: `1px solid ${colors.grayDark}`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
  backgroundColor: colors.black2,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    '&:hover fieldset': {
      borderColor: colors.blue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.blue,
    },
  },
  '& .MuiInputLabel-root': {
    color: colors.grayLight,
  },
  '& .MuiOutlinedInput-input': {
    color: colors.white,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: '3.0625rem',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  backgroundColor: colors.blueDark,
  color: colors.white,
  border: `1px solid ${colors.blue}`,
  boxShadow: `0 0 24px 10px ${colors.blue}33`,
  height: 56,
  '&:hover': {
    backgroundColor: colors.blue,
  },
}));

const AuthLinkText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: colors.grayLight,
}));

const AuthLinkButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: colors.blue,
  fontWeight: 600,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'rgba(41, 127, 255, 0.1)',
  },
}));

const OrDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '1.5rem 0',
  '&::before, &::after': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${colors.grayDark}`,
    opacity: 0.5,
  },
  '& span': {
    margin: '0 1rem',
    color: colors.grayLight,
    fontSize: '0.875rem',
  },
}));

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // State to toggle between login and signup
  const [isSignup, setIsSignup] = useState(false);

  // Add isGoogleSignIn state
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  // Form errors
  const [formErrors, setFormErrors] = useState({});
  // Password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Redux store data
  const { loading, error, signupSuccess, signupMessage, token } = useSelector((state) => state.auth);

  // If user is logged in, navigate to dashboard
  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (token || localToken) {
      navigate('/dashboard');
    }
  }, [navigate, token]);

  // Clear signup success on unmount
  useEffect(() => {
    return () => {
      dispatch(clearSignupSuccess());
    };
  }, [dispatch]);

  // Toggle between login / signup
  const toggleMode = () => {
    setIsSignup(!isSignup);
    setFormErrors({});
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    dispatch(clearSignupSuccess());
  };

  // Basic form validation
  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Company name is required';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    }

    if (isSignup) {
      if (!formData.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Invalid email address';
      }
      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords don't match";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const action = isSignup ? signup : login;
      await dispatch(action(formData)).unwrap();
    } catch (err) {
      console.error('Authentication error:', err);
    }
  };

  // Toggle password visibility
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Add this with your other refs/state
  const companyNameRef = React.useRef();

  return (
    <AuthContainer container>
      {/* LEFT PANEL */}
      <Grid item xs={12} md={6}>
        <LeftPanel>
          <Overlay>
            <BrandingBox>
              <Box
                component="img"
                src={logo}
                alt="Intratalent AI"
                sx={{ width: '150px', mb: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                intratalent ai
              </Typography>
            </BrandingBox>
          </Overlay>
        </LeftPanel>
      </Grid>

      {/* RIGHT PANEL */}
      <RightPanel item xs={12} md={6}>
        <FormWrapper
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: 3,
              textAlign: 'center',
              color: colors.white,
            }}
          >
            {isSignup ? 'Sign Up' : 'Login'}
          </Typography>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                marginBottom: 2, 
                borderRadius: '12px',
                backgroundColor: 'rgba(229, 57, 53, 0.1)',
                color: colors.error,
                border: `1px solid ${colors.error}`,
              }}
            >
              {error}
            </Alert>
          )}

          {signupSuccess && (
            <Alert 
              severity="success" 
              sx={{ 
                marginBottom: 2, 
                borderRadius: '12px',
                backgroundColor: 'rgba(76, 175, 80, 0.1)',
                color: colors.success,
                border: `1px solid ${colors.success}`,
              }}
            >
              {signupMessage}
            </Alert>
          )}

          {/* Add Google Sign-In Button */}
          <GoogleAuthButton 
            isSignup={isSignup} 
            companyNameRef={companyNameRef}
            setIsGoogleSignIn={setIsGoogleSignIn}
          />

          {isSignup && (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                mt: 1,
                mb: 1,
                color: colors.grayLight,
                fontSize: '0.875rem',
                fontStyle: 'italic',
              }}
            >
              By signing up with Google, you enable access for all members of your Google Workspace domain
            </Typography>
          )}

          {!isSignup && (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                mt: 1,
                mb: 1,
                color: colors.grayLight,
                fontSize: '0.875rem',
                fontStyle: 'italic',
              }}
            >
              Sign in with a Google Workspace account
            </Typography>
          )}

          <OrDivider>
            <span>or</span>
          </OrDivider>

          <form onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              variant="outlined"
              sx={{ textTransform: 'none' }}
            />

            {/* Email (only for Signup) */}
            {isSignup && (
              <StyledTextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                variant="outlined"
              />
            )}

            {/* Password */}
            <StyledTextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      onClick={handleShowPassword} 
                      edge="end"
                      sx={{ color: colors.grayLight }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Confirm Password (only for Signup) */}
            {isSignup && (
              <StyledTextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={!!formErrors.confirmPassword}
                helperText={formErrors.confirmPassword}
                variant="outlined"
              />
            )}

            <SubmitButton type="submit" fullWidth variant="contained" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : isSignup ? (
                'Sign Up'
              ) : (
                'Log In'
              )}
            </SubmitButton>
          </form>

          <Box sx={{ textAlign: 'center', mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AuthLinkText variant="body2">
              {isSignup ? 'Already have an account?' : "Don't have an account?"}
            </AuthLinkText>
            <AuthLinkButton onClick={toggleMode}>
              {isSignup ? 'Log In' : 'Sign Up'}
            </AuthLinkButton>
          </Box>
        </FormWrapper>
      </RightPanel>
    </AuthContainer>
  );
};

export default AuthPage;