import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Landing = () => {
  useEffect(() => {
    // Redirect to the static landing page
    // This avoids the JavaScript errors from WebFlow by loading the page directly
    window.location.href = '/landing/index.html';
    
    return () => {
      // No cleanup needed for redirect
    };
  }, []);

  // Return null since we're redirecting
  return null;
};

export default Landing; 