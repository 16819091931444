import { Provider } from 'react-redux';
import store from './store/configureStore';
import React from 'react';
import { useSelector } from 'react-redux';
import Home from './components/Home/Home';
import Landing from './components/Landing/Landing';
import ResultsDisplay from './components/ResultsDisplay/ResultsDisplay';
import ResumeUpload from './components/ResumeUpload/ResumeUpload';
import Dashboard from './components/Dashboard/Dashboard';
import AuthPage from './components/Auth/AuthPage';
import VerificationPage from './components/Auth/VerificationPage';
import VettingPage from './components/Vetting/VettingPage';
import VettingPage2 from './components/Vetting/VettingPage2';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';

import {
  Box,
  CssBaseline
} from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import intratalentTheme from './components/common/IntratalentTheme';
import IntratalentNavbar from './components/common/IntratalentNavbar';

// Protected route
const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

function AppContent() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <IntratalentNavbar />
      <Box sx={{ minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/signup" element={<AuthPage />} />
          <Route path="/verify-email/:token" element={<VerificationPage />} />
          <Route path="/demo" element={<ResultsDisplay />} />
          <Route 
            path="/vetting" 
            element={
              <ProtectedRoute>
                <VettingPage2 />
              </ProtectedRoute>
            } 
          />
          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search/:listingId"
            element={
              <ProtectedRoute>
                <ResultsDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload/:listingId"
            element={
              <ProtectedRoute>
                <ResumeUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search/all"
            element={
              <ProtectedRoute>
                <ResultsDisplay />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={intratalentTheme}>
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;