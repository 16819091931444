import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { childVariants } from './PageContainer';

// Styled components
const CardWrapper = styled(Paper)(({ theme, centered }) => ({
  borderRadius: '2rem',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  overflow: 'auto',
  width: '100%',
  maxWidth: '100%',
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 12px 28px rgba(0, 0, 0, 0.2)',
  },
  ...(centered && {
    alignItems: 'center',
    textAlign: 'center',
  }),
}));

const CardTag = styled(Box)(({ theme, noIcon }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '0.9rem',
  color: theme.palette.text.secondary,
  marginBottom: '1rem',
  ...(noIcon && {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    padding: '0.25rem 0.75rem',
    borderRadius: '0.75rem',
  }),
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.875rem',
  fontWeight: 500,
  lineHeight: 1.2,
  color: theme.palette.text.primary,
  marginBottom: '1.5rem',
}));

/**
 * ContentCard component that styles content like the WebFlow cards
 * @param {object} props Component props
 * @param {ReactNode} props.children Content to display inside the card
 * @param {string} props.tagText Text to display in the tag
 * @param {ReactNode} props.tagIcon Icon to display in the tag
 * @param {string} props.title Card title
 * @param {boolean} props.centered Whether to center content
 * @param {boolean} props.noAnimation Disable animation
 * @param {string} props.className Additional CSS class names
 * @param {object} props.sx Additional MUI sx props
 */
const ContentCard = ({ 
  children, 
  tagText, 
  tagIcon, 
  title, 
  centered = false, 
  noAnimation = false, 
  className,
  sx = {} 
}) => {
  const card = (
    <CardWrapper centered={centered} className={className} sx={sx}>
      {(tagText || tagIcon) && (
        <CardTag noIcon={!tagIcon}>
          {tagIcon && tagIcon}
          {tagText && <div>{tagText}</div>}
        </CardTag>
      )}
      
      {title && (
        <CardTitle variant="h2">{title}</CardTitle>
      )}
      
      {children}
    </CardWrapper>
  );
  
  // Apply animation if enabled
  if (noAnimation) {
    return card;
  }
  
  return (
    <motion.div variants={childVariants}>
      {card}
    </motion.div>
  );
};

export default ContentCard; 