// File: src/components/ResultsDisplay/ResultsDisplay.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress,
  Drawer,
  useMediaQuery,
  useTheme,
  InputAdornment,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Avatar,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TuneIcon from '@mui/icons-material/Tune';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import WeightSliders from '../Settings/WeightSliders';
import { generateWeights, searchCandidates, clearResults } from '../../services/searchSlice';
import PageContainer, { childVariants } from '../common/PageContainer';
import ContentCard from '../common/ContentCard';
import { colors } from '../common/IntratalentTheme';

// Utility for color gradient based on candidate score
const getGradientByScore = (score) => {
  if (score >= 75) {
    return 'linear-gradient(135deg, #297FFF 0%, #112F5A 100%)';
  } else if (score >= 50) {
    return 'linear-gradient(135deg, #112F5A 0%, #090909 100%)';
  }
  return 'linear-gradient(135deg, #4B4B4B 0%, #090909 100%)';
};

// =========== Styled Components ===========

const TruncatingTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '1rem',
    transition: 'box-shadow 0.2s ease',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.08)',
    },
    '&.Mui-focused': {
      boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    },
  },
}));

// ScoreChip styled component
const ScoreChip = styled(Box)(({ theme, score }) => ({
  width: '65px',
  height: '65px',
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    height: '45px',
    fontSize: '14px',
  },
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: '18px',
  color: '#fff',
  background: getGradientByScore(score),
  aspectRatio: '1/1',
  minWidth: '65px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
}));

// Education info area
const EducationChip = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(41, 127, 255, 0.1)',
  color: theme.palette.primary.main,
  padding: '4px 12px',
  borderRadius: '12px',
  fontSize: '13px',
  fontWeight: 500,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
}));

// Experience chip
const ExperienceChip = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(75, 75, 75, 0.1)',
  color: theme.palette.text.secondary,
  padding: '4px 12px',
  borderRadius: '12px',
  fontSize: '13px',
  fontWeight: 500,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
}));

// Arrow circle for opening details
const ArrowCircle = styled(IconButton)(({ theme }) => ({
  width: 36,
  height: 36,
  borderRadius: '50%',
  backgroundColor: 'rgba(41, 127, 255, 0.1)',
  color: theme.palette.primary.main,
  position: 'absolute',
  bottom: 16,
  right: 16,
  '&:hover': {
    backgroundColor: 'rgba(41, 127, 255, 0.2)',
  },
}));

// Styled drawer
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    maxWidth: '1200px',
    borderRadius: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '64px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '4px',
      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
}));

// Drawer Header
const DrawerHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '16px 24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

// Back Button
const BackButton = styled(IconButton)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  marginRight: '16px',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

// Project Card
const ProjectCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '16px',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(3),
  border: '1px solid rgba(255, 255, 255, 0.08)',
  transition: 'all 0.2s ease',
  width: '100%',
  maxWidth: '100%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

// =========== Main Component ===========

function ResultsDisplay() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { candidates, weightsLoading, searchLoading, error, key_experiences } =
    useSelector((state) => state.search);

  const location = useLocation();
  const { listingId: urlListingId } = useParams();
  const listing = location.state?.listing;
  const listingId = urlListingId || listing?.id;
  const isAllSearch = urlListingId === 'all';

  // job description
  const [jobDescription, setJobDescription] = useState('');
  const [showError, setShowError] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  // weighting
  const [adjustedWeights, setAdjustedWeights] = useState([]);
  const [minGpa, setMinGpa] = useState(3.7);

  // processed results
  const [processedCandidates, setProcessedCandidates] = useState([]);

  // searching progress
  const [showSearchProgress, setShowSearchProgress] = useState(false);
  const [searchProgress, setSearchProgress] = useState(0);

  // drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  // log for fine-tuning
  const [logForFinetuning, setLogForFinetuning] = useState(false);

  // On mount
  useEffect(() => {
    if (listing?.job_description) {
      setJobDescription(listing.job_description);
      setWordCount(countWords(listing.job_description));
    }
    return () => {
      dispatch(clearResults());
    };
  }, [dispatch, listing]);

  useEffect(() => {
    setProcessedCandidates(candidates);
  }, [candidates]);

  useEffect(() => {
    if (!searchLoading) {
      if (showSearchProgress) {
        // When search completes, briefly show 100% before hiding
        setSearchProgress(100);
        const timer = setTimeout(() => {
          setShowSearchProgress(false);
          setSearchProgress(0);
        }, 500);
        return () => clearTimeout(timer);
      }
      return;
    }
    
    setShowSearchProgress(true);
    setSearchProgress(0);

    // More responsive faux progress bar
    const duration = 60000; // 1 minute max
    const target = 95; // Only go to 95%, the last 5% when complete
    const interval = 80;
    const initialJump = 10; // Jump quickly to 10% for better UX
    
    // Quick initial jump to show activity
    setSearchProgress(initialJump);
    
    // Then slower incremental progress
    const nonLinearProgress = (currentProgress) => {
      // Slow down as we get closer to the target
      if (currentProgress < 30) return currentProgress + 0.7;
      if (currentProgress < 60) return currentProgress + 0.4;
      if (currentProgress < 85) return currentProgress + 0.12;
      return currentProgress + 0.05;
    };

    const timer = setInterval(() => {
      setSearchProgress((prev) => {
        if (prev >= target) {
          clearInterval(timer);
          return target;
        }
        return nonLinearProgress(prev);
      });
    }, interval);

    return () => clearInterval(timer);
  }, [searchLoading, showSearchProgress]);

  // Counting words
  const countWords = (text) =>
    text.trim().split(/\s+/).filter((w) => w.length > 0).length;

  // Handle job desc changes
  const handleTextChange = (e) => {
    const text = e.target.value;
    setJobDescription(text);
    setWordCount(countWords(text));
    setShowError(false);
  };

  // Generate Key Experiences
  const handleGenerateWeights = async () => {
    if (wordCount < 20) {
      setShowError(true);
      return;
    }
    if (!jobDescription.trim()) {
      alert('Please enter a job description.');
      return;
    }
    await dispatch(generateWeights(jobDescription));
  };

  // Final search
  const handleSearch = async () => {
    if (!jobDescription.trim()) {
      alert('Please enter a job description.');
      return;
    }

    if (adjustedWeights.length === 0) {
      alert('Please generate weights first.');
      return;
    }

    await dispatch(
      searchCandidates({
        query: jobDescription,
        weights: adjustedWeights,
        listingId: listingId,
        minGpa: minGpa,
        logForFinetuning: logForFinetuning,
      })
    );
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    console.log('Selected candidate data:', candidate);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <PageContainer>
      <motion.div variants={childVariants}>
        <Typography variant="h1" gutterBottom>
          AI-Powered Candidate Search
        </Typography>
        <Typography 
          variant="body1" 
          color="textSecondary" 
          textAlign="center" 
          sx={{ mb: 4, maxWidth: '900px', mx: 'auto' }}
        >
          Input a job description below to find the most relevant candidates based on our AI matching algorithm.
        </Typography>
      </motion.div>

      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} lg={5}>
          <ContentCard 
            tagIcon={<SearchIcon sx={{ color: theme.palette.primary.main }} />}
            tagText="Job Description"
            title="Find Top Candidates"
            sx={{ height: '100%' }}
          >
            <Box sx={{ mb: 3 }}>
              <TruncatingTextField
                fullWidth
                placeholder="Enter job description or paste JD text here..."
                multiline
                rows={10}
                value={jobDescription}
                onChange={handleTextChange}
                variant="outlined"
                error={showError}
                helperText={
                  showError
                    ? 'Please enter at least 20 words for better results.'
                    : `${wordCount} words`
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TuneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Skill Importance</Typography>
              <Button
                variant="outlined"
                onClick={handleGenerateWeights}
                disabled={weightsLoading || wordCount < 20}
              >
                {weightsLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Generate Weights'
                )}
              </Button>
            </Box>

            {key_experiences.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <WeightSliders
                  experiences={key_experiences}
                  onWeightsChange={setAdjustedWeights}
                  onSearch={handleSearch}
                  searchLoading={searchLoading}
                  onGpaChange={setMinGpa}
                />
              </Box>
            )}

            {showSearchProgress && (
              <Box sx={{ width: '100%', mt: 2, mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" color="primary" fontWeight="500">
                    Searching candidates
                  </Typography>
                  <Box sx={{ flex: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    {`${Math.round(searchProgress)}%`}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={searchProgress}
                  sx={{ 
                    height: 10, 
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5,
                      background: 'linear-gradient(90deg, #112F5A 0%, #297FFF 100%)',
                      transition: 'transform 0.3s ease'
                    }
                  }}
                />
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', fontStyle: 'italic' }}>
                  {searchProgress < 30 ? 'Analyzing job description...' :
                   searchProgress < 60 ? 'Matching with candidates...' :
                   searchProgress < 85 ? 'Ranking results...' :
                   'Finalizing results...'}
                </Typography>
              </Box>
            )}
          </ContentCard>
        </Grid>

        {/* Right Column - Results */}
        <Grid item xs={12} lg={7}>
          <ContentCard
            tagIcon={<WorkIcon sx={{ color: theme.palette.primary.main }} />}
            tagText="Results"
            title={processedCandidates.length > 0 ? "Top Candidates" : "Candidates will appear here"}
            sx={{ height: '100%' }}
          >
            {error && (
              <Box sx={{ mb: 4, p: 3, backgroundColor: 'rgba(229, 57, 53, 0.1)', borderRadius: 2 }}>
                <Typography color="error" sx={{ mb: 1, fontWeight: 500 }}>
                  Search Error
                </Typography>
                <Typography color="error.dark" variant="body2">
                  {error.includes('backend') ? 'Server connection error. Please try again later.' :
                   error.includes('format') ? 'The search results were in an unexpected format.' :
                   error}
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  sx={{ mt: 2 }}
                  onClick={() => dispatch(clearResults())}
                >
                  Clear Error
                </Button>
              </Box>
            )}

            {processedCandidates.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                {processedCandidates.map((candidate, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        padding: 4,
                        mb: 3,
                        borderRadius: 3,
                        bgcolor: 'rgba(255, 255, 255, 0.03)',
                        border: '1px solid rgba(255, 255, 255, 0.08)',
                        transition: 'all 0.2s ease',
                        width: '100%',
                        '&:hover': {
                          bgcolor: 'rgba(255, 255, 255, 0.05)',
                          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                        <Avatar
                          src={candidate.avatar_url}
                          alt={candidate.name}
                          sx={{ 
                            width: 70, 
                            height: 70, 
                            mr: 3,
                            backgroundColor: 'rgba(41, 127, 255, 0.1)'
                          }}
                        >
                          {candidate.name ? candidate.name.charAt(0) : '?'}
                        </Avatar>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="h6" component="div" fontWeight="500">
                            {candidate.name || 'Candidate ' + (index + 1)}
                          </Typography>
                          
                          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                            {candidate.headline || 
                              (candidate.latest_position ? 
                                candidate.latest_position.title + ' at ' + candidate.latest_position.company_name : 
                                '')
                            }
                          </Typography>
                          
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                            {candidate.education && candidate.education[0] && (
                              <EducationChip>
                                <SchoolIcon fontSize="small" />
                                {candidate.education[0].school_name}
                              </EducationChip>
                            )}
                            
                            {candidate.experience && candidate.experience[0] && (
                              <ExperienceChip>
                                <WorkIcon fontSize="small" />
                                {candidate.experience[0].company_name}
                              </ExperienceChip>
                            )}
                          </Box>
                          
                          <Box sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                              {candidate.match_reason || 'Matched based on your job description requirements'}
                            </Typography>
                          </Box>
                          
                          {/* Match Highlights */}
                          {candidate.experience && candidate.experience.some(e => e.match_score) && (
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="caption" color="primary" sx={{ mb: 0.5, display: 'block', fontWeight: 500 }}>
                                Match Highlights:
                              </Typography>
                              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                {candidate.experience
                                  .filter(exp => exp.match_score && exp.match_score > 60)
                                  .slice(0, 3)
                                  .map((exp, idx) => (
                                    <Box key={idx} sx={{ 
                                      display: 'flex', 
                                      alignItems: 'center', 
                                      backgroundColor: 'rgba(41, 127, 255, 0.1)',
                                      borderRadius: '12px',
                                      padding: '3px 8px',
                                      fontSize: '0.75rem',
                                    }}>
                                      <Box sx={{ 
                                        width: 22, 
                                        height: 22, 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white',
                                        mr: 1,
                                        fontSize: '0.65rem',
                                        fontWeight: 'bold'
                                      }}>
                                        {Math.round(exp.match_score)}
                                      </Box>
                                      <Typography variant="caption" noWrap sx={{ maxWidth: 120 }}>
                                        {exp.title || exp.position || exp.role || 'Experience'}
                                      </Typography>
                                    </Box>
                                ))}
                              </Box>
                            </Box>
                          )}
                        </Box>
                        
                        <ScoreChip score={candidate.score || 0} sx={{ 
                          aspectRatio: '1/1',
                          minWidth: '65px'
                        }}>
                          {Math.round(candidate.score)}%
                        </ScoreChip>
                      </Box>
                      
                      <ArrowCircle onClick={() => handleCandidateClick(candidate)}>
                        <OpenInNewIcon fontSize="small" />
                      </ArrowCircle>
                    </Box>
                  </motion.div>
                ))}
                
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Typography variant="body2" color="textSecondary">
                    {processedCandidates.length} candidates matched your criteria
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', py: 6 }}>
                {!searchLoading && (
                  <Typography color="textSecondary">
                    Submit a job description to view matching candidates.
                  </Typography>
                )}
              </Box>
            )}
          </ContentCard>
        </Grid>
      </Grid>

      {/* Candidate Detail Drawer */}
      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <BackButton onClick={handleDrawerClose}>
            <KeyboardArrowLeftIcon />
          </BackButton>
          <Typography variant="h6">Candidate Details</Typography>
        </DrawerHeader>
        
        {selectedCandidate && (
          <Box sx={{ p: 5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
              <Avatar
                src={selectedCandidate.avatar_url}
                alt={selectedCandidate.name}
                sx={{ width: 120, height: 120, mr: 4, backgroundColor: 'rgba(41, 127, 255, 0.2)' }}
              >
                {selectedCandidate.name ? selectedCandidate.name.charAt(0) : '?'}
              </Avatar>
              <Box>
                <Typography variant="h4" component="div" sx={{ fontWeight: 600, mb: 1 }}>
                  {selectedCandidate.name || 'Unnamed Candidate'}
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ fontSize: '1.1rem' }}>
                  {selectedCandidate.headline || 
                    (selectedCandidate.latest_position ? 
                      selectedCandidate.latest_position.title + ' at ' + selectedCandidate.latest_position.company_name : 
                      '')
                  }
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                  <ScoreChip score={selectedCandidate.score || 0} sx={{ 
                    width: 70, 
                    height: 70, 
                    fontSize: '1.25rem', 
                    fontWeight: 700,
                    minWidth: '70px',
                    aspectRatio: '1/1'
                  }}>
                    {Math.round(selectedCandidate.score)}%
                  </ScoreChip>
                  <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                    Match Score
                  </Typography>
                </Box>
              </Box>
            </Box>
            
            <Divider sx={{ my: 5 }} />
            
            {/* Experience */}
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              color: theme.palette.primary.main,
              fontWeight: 600,
              fontSize: '1.5rem',
              mb: 3
            }}>
              <WorkIcon fontSize="large" /> Experience
            </Typography>
            
            {/* Try to find and display experience information */}
            {(() => {
              // Check for structured experience data
              if (selectedCandidate.experience && selectedCandidate.experience.length > 0) {
                return selectedCandidate.experience.map((exp, index) => (
                  <ProjectCard key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                          {exp.title || exp.position || exp.role || exp.name || 'Position'} 
                          {(exp.company_name || exp.company || exp.organization) && 
                            ` at ${exp.company_name || exp.company || exp.organization || 'Company'}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 1.5 }}>
                          {exp.date_range || (exp.start_date && `${exp.start_date} - ${exp.end_date || 'Present'}`) || 
                            (exp.dates && exp.dates) || 'No date information'} 
                          {exp.location && ` • ${exp.location}`}
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6 }}>
                          {exp.description || exp.details || exp.summary || 'No description available'}
                        </Typography>
                      </Box>
                      {exp.match_score && (
                        <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <ScoreChip score={exp.match_score} sx={{ 
                            width: 50, 
                            height: 50, 
                            fontSize: '1rem', 
                            minWidth: '50px',
                            mb: 1
                          }}>
                            {Math.round(exp.match_score)}%
                          </ScoreChip>
                          <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>
                            Match
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    
                    {exp.match_reason && (
                      <Box sx={{ mt: 2, p: 2, backgroundColor: 'rgba(41, 127, 255, 0.05)', borderRadius: 2, borderLeft: `3px solid ${colors.blue}` }}>
                        <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 500, mb: 1 }}>
                          Why this experience matches:
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontStyle: 'italic' }}>
                          {exp.match_reason}
                        </Typography>
                      </Box>
                    )}
                  </ProjectCard>
                ));
              }
              
              // Check for data in any similarly named arrays
              for (const key in selectedCandidate) {
                if (Array.isArray(selectedCandidate[key]) && 
                    selectedCandidate[key].length > 0 &&
                    (key.includes('experience') || key.includes('job') || key.includes('work') || key.includes('position'))) {
                  // If array of objects
                  if (typeof selectedCandidate[key][0] === 'object') {
                    return selectedCandidate[key].map((exp, index) => (
                      <ProjectCard key={index}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                              {exp.title || exp.position || exp.role || exp.name || 'Position'} 
                              {(exp.company_name || exp.company || exp.organization) && 
                                ` at ${exp.company_name || exp.company || exp.organization || 'Company'}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1.5 }}>
                              {exp.date_range || (exp.start_date && `${exp.start_date} - ${exp.end_date || 'Present'}`) || 
                                (exp.dates && exp.dates) || 'No date information'} 
                              {exp.location && ` • ${exp.location}`}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6 }}>
                              {exp.description || exp.details || exp.summary || 'No description available'}
                            </Typography>
                          </Box>
                          {exp.match_score && (
                            <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <ScoreChip score={exp.match_score} sx={{ 
                                width: 50, 
                                height: 50, 
                                fontSize: '1rem', 
                                minWidth: '50px',
                                mb: 1
                              }}>
                                {Math.round(exp.match_score)}%
                              </ScoreChip>
                              <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>
                                Match
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        
                        {exp.match_reason && (
                          <Box sx={{ mt: 2, p: 2, backgroundColor: 'rgba(41, 127, 255, 0.05)', borderRadius: 2, borderLeft: `3px solid ${colors.blue}` }}>
                            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 500, mb: 1 }}>
                              Why this experience matches:
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontStyle: 'italic' }}>
                              {exp.match_reason}
                            </Typography>
                          </Box>
                        )}
                      </ProjectCard>
                    ));
                  }
                  // If array of strings
                  else if (typeof selectedCandidate[key][0] === 'string') {
                    return (
                      <ProjectCard>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                          Experience Details
                        </Typography>
                        <Box component="ul" sx={{ pl: 2 }}>
                          {selectedCandidate[key].map((item, idx) => (
                            <Typography component="li" key={idx} variant="body2" sx={{ mb: 1 }}>
                              {item}
                            </Typography>
                          ))}
                        </Box>
                      </ProjectCard>
                    );
                  }
                }
              }
              
              // Check for experience in projects
              if (selectedCandidate.projects && selectedCandidate.projects.length > 0) {
                return selectedCandidate.projects.map((project, index) => (
                  <ProjectCard key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                          {project.project_name || project.name || 'Project'} 
                          {project.company && ` at ${project.company}`}
                        </Typography>
                        <Typography variant="body2" sx={{ 
                          color: theme.palette.text.primary,
                          lineHeight: 1.6
                        }}>
                          {project.description || project.details || 'No description available'}
                        </Typography>
                      </Box>
                      {project.match_score && (
                        <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <ScoreChip score={project.match_score} sx={{ 
                            width: 50, 
                            height: 50, 
                            fontSize: '1rem', 
                            minWidth: '50px',
                            mb: 1
                          }}>
                            {Math.round(project.match_score)}%
                          </ScoreChip>
                          <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>
                            Match
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    
                    {project.match_reason && (
                      <Box sx={{ mt: 2, p: 2, backgroundColor: 'rgba(41, 127, 255, 0.05)', borderRadius: 2, borderLeft: `3px solid ${colors.blue}` }}>
                        <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 500, mb: 1 }}>
                          Why this project matches:
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontStyle: 'italic' }}>
                          {project.match_reason}
                        </Typography>
                      </Box>
                    )}
                  </ProjectCard>
                ));
              }
              
              // Direct access to experience_details
              if (selectedCandidate.experience_details) {
                return (
                  <ProjectCard>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      Experience Details
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6 }}>
                      {selectedCandidate.experience_details}
                    </Typography>
                  </ProjectCard>
                );
              }

              // Display raw data as a last resort
              const possibleExperienceFields = ['experience', 'experiences', 'work_experience', 'job_history', 'work_history', 'positions'];
              for (const field of possibleExperienceFields) {
                if (selectedCandidate[field] && typeof selectedCandidate[field] === 'string' && selectedCandidate[field].length > 10) {
                  return (
                    <ProjectCard>
                      <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                        Experience Details
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                        {selectedCandidate[field]}
                      </Typography>
                    </ProjectCard>
                  );
                }
              }
              
              // If nothing else, check if there are any fields with "experience" in the name at all
              for (const key in selectedCandidate) {
                if (typeof selectedCandidate[key] === 'string' && 
                    (key.toLowerCase().includes('exp') || key.toLowerCase().includes('job') || key.toLowerCase().includes('work')) && 
                    selectedCandidate[key].length > 5) {
                  return (
                    <ProjectCard>
                      <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                        {selectedCandidate[key]}
                      </Typography>
                    </ProjectCard>
                  );
                }
              }
              
              return (
                <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', mb: 2 }}>
                  No experience information available
                </Typography>
              );
            })()}
            
            <Divider sx={{ my: 5 }} />
            
            {/* Education */}
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              color: theme.palette.primary.main,
              fontWeight: 600,
              fontSize: '1.5rem',
              mb: 3
            }}>
              <SchoolIcon fontSize="large" /> Education
            </Typography>
            
            {/* Try to find and display education information */}
            {(() => {
              // Check for structured education data
              if (selectedCandidate.education && selectedCandidate.education.length > 0) {
                return selectedCandidate.education.map((edu, index) => (
                  <ProjectCard key={index}>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      {edu.school_name || edu.school || edu.institution || 'School/University'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                      {edu.degree_name || edu.degree || edu.qualification || 'Degree'} 
                      {(edu.field_of_study || edu.major) && ` • ${edu.field_of_study || edu.major}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {edu.date_range || (edu.start_date && `${edu.start_date} - ${edu.end_date || 'Present'}`) || 'No date information'}
                      {(edu.grade || edu.gpa) && ` • GPA: ${edu.grade || edu.gpa}`}
                    </Typography>
                  </ProjectCard>
                ));
              }
              
              // Check for alternative field names
              if (selectedCandidate.educations && selectedCandidate.educations.length > 0) {
                return selectedCandidate.educations.map((edu, index) => (
                  <ProjectCard key={index}>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      {edu.school_name || edu.school || edu.institution || 'School/University'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                      {edu.degree_name || edu.degree || edu.qualification || 'Degree'} 
                      {(edu.field_of_study || edu.major) && ` • ${edu.field_of_study || edu.major}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {edu.date_range || (edu.start_date && `${edu.start_date} - ${edu.end_date || 'Present'}`) || 'No date information'}
                      {(edu.grade || edu.gpa) && ` • GPA: ${edu.grade || edu.gpa}`}
                    </Typography>
                  </ProjectCard>
                ));
              }
              
              // Check for data in any similarly named arrays
              for (const key in selectedCandidate) {
                if (Array.isArray(selectedCandidate[key]) && 
                    selectedCandidate[key].length > 0 &&
                    (key.includes('education') || key.includes('school') || key.includes('academic') || key.includes('degree'))) {
                  // If array of objects
                  if (typeof selectedCandidate[key][0] === 'object') {
                    return selectedCandidate[key].map((edu, index) => (
                      <ProjectCard key={index}>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                          {edu.school_name || edu.school || edu.institution || edu.name || 'School/University'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                          {edu.degree_name || edu.degree || edu.qualification || edu.title || 'Degree'} 
                          {(edu.field_of_study || edu.major || edu.field) && ` • ${edu.field_of_study || edu.major || edu.field}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {edu.date_range || (edu.start_date && `${edu.start_date} - ${edu.end_date || 'Present'}`) || 
                           (edu.dates && edu.dates) || 'No date information'}
                          {(edu.grade || edu.gpa) && ` • GPA: ${edu.grade || edu.gpa}`}
                        </Typography>
                      </ProjectCard>
                    ));
                  }
                  // If array of strings
                  else if (typeof selectedCandidate[key][0] === 'string') {
                    return (
                      <ProjectCard>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                          Education Details
                        </Typography>
                        <Box component="ul" sx={{ pl: 2 }}>
                          {selectedCandidate[key].map((item, idx) => (
                            <Typography component="li" key={idx} variant="body2" sx={{ mb: 1 }}>
                              {item}
                            </Typography>
                          ))}
                        </Box>
                      </ProjectCard>
                    );
                  }
                }
              }
              
              // Check for education_details as a string
              if (selectedCandidate.education_details) {
                return (
                  <ProjectCard>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      Education Details
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                      {selectedCandidate.education_details}
                    </Typography>
                  </ProjectCard>
                );
              }

              // Display raw data as a last resort
              const possibleEducationFields = ['education', 'educations', 'academic_history', 'schools', 'universities'];
              for (const field of possibleEducationFields) {
                if (selectedCandidate[field] && typeof selectedCandidate[field] === 'string' && selectedCandidate[field].length > 10) {
                  return (
                    <ProjectCard>
                      <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                        Education Details
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                        {selectedCandidate[field]}
                      </Typography>
                    </ProjectCard>
                  );
                }
              }
              
              // Check for direct education-related text fields
              for (const key in selectedCandidate) {
                if (typeof selectedCandidate[key] === 'string' && 
                    (key.toLowerCase().includes('educ') || key.toLowerCase().includes('school') || key.toLowerCase().includes('degree')) && 
                    selectedCandidate[key].length > 5) {
                  return (
                    <ProjectCard>
                      <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                        {selectedCandidate[key]}
                      </Typography>
                    </ProjectCard>
                  );
                }
              }
              
              // Check GPA if available
              if (selectedCandidate.undergrad_gpa && selectedCandidate.undergrad_gpa > 0) {
                return (
                  <ProjectCard>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      GPA
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6 }}>
                      Undergraduate GPA: {selectedCandidate.undergrad_gpa}
                    </Typography>
                  </ProjectCard>
                );
              }
              
              // Determine highest education level
              const findHighestEducation = () => {
                const eduLevels = {
                  'phd': 5, 'doctorate': 5, 'ph.d': 5, 'doctoral': 5,
                  'master': 4, 'mba': 4, 'ms': 4, 'ma': 4, 'm.s': 4, 'm.a': 4, 'm.b.a': 4,
                  'bachelor': 3, 'bs': 3, 'ba': 3, 'b.s': 3, 'b.a': 3, 'undergraduate': 3,
                  'associate': 2, 'diploma': 2, 'certification': 2,
                  'high school': 1, 'secondary': 1, 'hs': 1
                };
                
                let highestLevel = 0;
                let highestSchool = '';
                let highestDegree = '';
                
                // Check for education arrays
                const checkEduArray = (eduArray) => {
                  if (!Array.isArray(eduArray) || eduArray.length === 0) return false;
                  
                  eduArray.forEach(edu => {
                    const degree = edu.degree_name || edu.degree || edu.qualification || edu.title || '';
                    const school = edu.school_name || edu.school || edu.institution || edu.name || '';
                    
                    for (const [level, value] of Object.entries(eduLevels)) {
                      if (degree.toLowerCase().includes(level) && value > highestLevel) {
                        highestLevel = value;
                        highestDegree = degree;
                        highestSchool = school;
                        return;
                      }
                    }
                  });
                  
                  return highestLevel > 0;
                };
                
                // Check structured education data
                if (selectedCandidate.education) {
                  if (checkEduArray(selectedCandidate.education)) return { highestDegree, highestSchool };
                }
                
                if (selectedCandidate.educations) {
                  if (checkEduArray(selectedCandidate.educations)) return { highestDegree, highestSchool };
                }
                
                // Check other education arrays
                for (const key in selectedCandidate) {
                  if (Array.isArray(selectedCandidate[key]) && 
                      selectedCandidate[key].length > 0 &&
                      (key.includes('education') || key.includes('academic'))) {
                    if (checkEduArray(selectedCandidate[key])) return { highestDegree, highestSchool };
                  }
                }
                
                // Check strings for education info
                for (const key in selectedCandidate) {
                  if (typeof selectedCandidate[key] === 'string') {
                    const value = selectedCandidate[key].toLowerCase();
                    for (const [level, levelValue] of Object.entries(eduLevels)) {
                      if (value.includes(level) && levelValue > highestLevel) {
                        highestLevel = levelValue;
                        // Try to extract school name - simple approximation
                        const parts = value.split(/ at | from /);
                        if (parts.length > 1) {
                          highestSchool = parts[1].trim();
                          highestDegree = level.charAt(0).toUpperCase() + level.slice(1);
                        } else {
                          highestDegree = level.charAt(0).toUpperCase() + level.slice(1);
                        }
                      }
                    }
                  }
                }
                
                return highestLevel > 0 ? { highestDegree, highestSchool } : null;
              };
              
              const educationInfo = findHighestEducation();
              if (educationInfo) {
                return (
                  <ProjectCard>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                      Highest Education
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6 }}>
                      {educationInfo.highestDegree}{educationInfo.highestSchool ? ` at ${educationInfo.highestSchool}` : ''}
                    </Typography>
                  </ProjectCard>
                );
              }
              
              return (
                <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', mb: 2 }}>
                  No education information available
                </Typography>
              );
            })()}
            
            <Divider sx={{ my: 5 }} />
            
            {/* Skills */}
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              color: theme.palette.primary.main,
              fontWeight: 600,
              fontSize: '1.5rem',
              mb: 3
            }}>
              <TuneIcon fontSize="large" /> Skills
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {(() => {
                // Try multiple potential skill field names
                if (selectedCandidate.skills && selectedCandidate.skills.length > 0) {
                  return selectedCandidate.skills.map((skill, index) => (
                    <EducationChip key={index}>
                      {skill}
                    </EducationChip>
                  ));
                } else if (selectedCandidate.skill_set && selectedCandidate.skill_set.length > 0) {
                  return selectedCandidate.skill_set.map((skill, index) => (
                    <EducationChip key={index}>
                      {skill}
                    </EducationChip>
                  ));
                } else if (selectedCandidate.top_skills && selectedCandidate.top_skills.length > 0) {
                  return selectedCandidate.top_skills.map((skill, index) => (
                    <EducationChip key={index}>
                      {skill}
                    </EducationChip>
                  ));
                } else {
                  // Look for any array that might be skills
                  for (const key in selectedCandidate) {
                    if (
                      Array.isArray(selectedCandidate[key]) && 
                      selectedCandidate[key].length > 0 &&
                      (key.includes('skill') || key.includes('tag') || key.includes('keyword')) &&
                      typeof selectedCandidate[key][0] === 'string'
                    ) {
                      return selectedCandidate[key].map((skill, index) => (
                        <EducationChip key={index}>
                          {skill}
                        </EducationChip>
                      ));
                    }
                  }
                  
                  // Check for direct skills string
                  if (selectedCandidate.skills_text && typeof selectedCandidate.skills_text === 'string') {
                    const skillsArray = selectedCandidate.skills_text.split(/,|;|\n/).map(s => s.trim()).filter(s => s.length > 0);
                    if (skillsArray.length > 0) {
                      return skillsArray.map((skill, index) => (
                        <EducationChip key={index}>
                          {skill}
                        </EducationChip>
                      ));
                    }
                  }
                  
                  // Last resort - parse any skills-related field
                  for (const key in selectedCandidate) {
                    if (typeof selectedCandidate[key] === 'string' && 
                        (key.toLowerCase().includes('skill') || key.toLowerCase().includes('competenc') || key.toLowerCase().includes('abilit')) && 
                        selectedCandidate[key].length > 5) {
                      return (
                        <ProjectCard>
                          <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                          </Typography>
                          <Typography variant="body2" sx={{ color: theme.palette.text.primary, lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
                            {selectedCandidate[key]}
                          </Typography>
                        </ProjectCard>
                      );
                    }
                  }
                  
                  return (
                    <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', mb: 2 }}>
                      No skills information available
                    </Typography>
                  );
                }
              })()}
            </Box>
            
            <Box sx={{ mt: 4 }}>
              <Button 
                variant="contained" 
                fullWidth
                sx={{
                  borderRadius: '3.0625rem',
                  textTransform: 'none',
                  fontWeight: 600,
                  padding: '0.75rem 1.5rem',
                  transition: 'all 0.3s',
                  height: 56,
                  boxShadow: `0 0 24px 10px ${colors.blue}33`,
                  backgroundColor: colors.blueDark,
                  border: `1px solid ${colors.blue}`,
                  color: colors.white,
                  '&:hover': {
                    backgroundColor: colors.blue,
                  },
                }}
              >
                Contact Candidate
              </Button>
            </Box>
          </Box>
        )}
      </StyledDrawer>
    </PageContainer>
  );
}

export default ResultsDisplay;
