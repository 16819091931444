import { createTheme } from '@mui/material/styles';
import '@fontsource/plus-jakarta-sans';

// Colors extracted from the Webflow landing page CSS
const colors = {
  black1: '#090909',
  black2: '#0F0F0F',
  black3: '#181818',
  black4: '#202020',
  grayDark: '#4B4B4B',
  grayLight: '#B2B2B2',
  white: '#FFFFFF',
  blue: '#297FFF',
  blueDark: '#112F5A',
  accent: '#72879D',
  // Added additional colors for UI components
  error: '#E53935',
  success: '#4CAF50',
  warning: '#FFC107',
  info: '#2196F3',
};

// Create a theme instance
const intratalentTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.blue,
      dark: colors.blueDark,
    },
    secondary: {
      main: colors.grayDark,
    },
    background: {
      default: colors.black1,
      paper: colors.black2,
      card: colors.black3,
    },
    text: {
      primary: colors.white,
      secondary: colors.grayLight,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '3.875rem',
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: '0.5px',
      textAlign: 'center',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: '0.5px',
      marginTop: '1.25rem',
      marginBottom: '1.5rem',
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 400,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      color: colors.grayLight,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.7px',
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '3.0625rem',
          padding: '0.75rem 1.5rem',
          transition: 'all 0.3s',
          height: 56,
          boxShadow: `0 0 24px 10px ${colors.blue}33`,
        },
        contained: {
          backgroundColor: colors.blueDark,
          border: `1px solid ${colors.blue}`,
          '&:hover': {
            backgroundColor: colors.blue,
          },
        },
        outlined: {
          borderColor: 'rgba(255, 255, 255, 0.08)',
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          color: colors.white,
          boxShadow: 'none',
          '&:hover': {
            borderColor: colors.blue,
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
        text: {
          color: colors.white,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: colors.black2,
          border: `0.5px solid ${colors.grayDark}`,
          borderRadius: '2.375rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black2,
          border: `0.5px solid ${colors.grayDark}`,
          borderRadius: '2.375rem',
          padding: '2rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '2rem',
          '&:last-child': {
            paddingBottom: '2rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '1rem',
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.08)',
            },
            '&:hover fieldset': {
              borderColor: colors.blue,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.blue,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: colors.grayLight,
            opacity: 0.7,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(41, 127, 255, 0.1)',
          color: colors.blue,
          borderRadius: '12px',
          fontWeight: 500,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grayDark,
          opacity: 0.5,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          borderRadius: 4,
        },
        bar: {
          backgroundColor: colors.blue,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.blue,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '1rem 0',
          borderBottom: `1px solid ${colors.grayDark}30`,
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.black2,
          borderLeft: `1px solid ${colors.grayDark}`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.white,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
  },
});

export default intratalentTheme;
export { colors }; 