import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { googleLogin, googleWorkspaceSignup } from '../../services/authSlice';
import { Button, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { colors } from '../common/IntratalentTheme';
import { styled } from '@mui/system';

const GoogleButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '45px',
  marginTop: '1rem',
  marginBottom: '1rem',
  borderRadius: '12px',
  padding: '0.25rem',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  border: `1px solid ${colors.grayDark}`,
}));

const GoogleAuthButton = ({ isSignup, companyNameRef, setIsGoogleSignIn }) => {
  const dispatch = useDispatch();
  const [googleLoaded, setGoogleLoaded] = useState(false);

  const handleGoogleAuth = async (response) => {
    const token = response.credential;
    
    if (isSignup) {
      // For signup, we'll get the company name from the token on the backend
      dispatch(googleWorkspaceSignup({ token }));
    } else {
      dispatch(googleLogin(token));
    }
  };

  React.useEffect(() => {
    // Load Google Sign-In script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => setGoogleLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  React.useEffect(() => {
    if (googleLoaded && window.google) {
      // Initialize Google Sign-In
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleAuth,
      });

      // Render Google Button with dark theme
      window.google.accounts.id.renderButton(
        document.getElementById('googleButton'),
        { 
          type: 'standard',
          theme: 'filled_black', // Use dark theme for Google button
          size: 'large',
          text: isSignup ? 'signup_with' : 'signin_with',
          width: '280',
          height: '45',
          logo_alignment: 'center'
        }
      );
    }
  }, [googleLoaded, isSignup]);

  return (
    <GoogleButtonContainer>
      <div 
        id="googleButton" 
        style={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '45px',
        }}
      ></div>
    </GoogleButtonContainer>
  );
};

export default GoogleAuthButton; 