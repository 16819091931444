import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip, 
  Snackbar,
  CircularProgress,
  Alert,
  Chip,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { 
  LinkedIn as LinkedInIcon, 
  GitHub as GitHubIcon, 
  Link as LinkIcon, 
  Work as WorkIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  ContentCopy as ContentCopyIcon,
  CloudUpload as CloudUploadIcon,
  LocationOn,
  Info as InfoIcon,
} from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { 
  fetchCandidate, 
  fetchCompanyEmployees, 
  fetchColleagues, 
  fetchRankedColleagues,
  compareWithConnections,
  clearVetState,
  fetchGithubCollaborators,
  uploadLinkedinConnections,
  processResumeExperience,
  sendReferenceEmail,
  clearConnectionsUploadState,
  clearResumeState
} from '../../services/vetSlice';
import ColleagueCard from './ColleagueCard';
import api from '../../services/api';
import PageContainer, { childVariants } from '../common/PageContainer';
import ContentCard from '../common/ContentCard';
import { styled } from '@mui/material/styles';

// Styled components
const UploadBox = styled(Box)(({ theme, $isDragActive }) => ({
  border: `2px dashed ${$isDragActive ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: '1rem',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  backgroundColor: $isDragActive ? 'rgba(41, 127, 255, 0.05)' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderColor: theme.palette.text.secondary,
  },
}));

const VettingPage2 = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  
  // Local state
  const [linkedinUsername, setLinkedinUsername] = useState('');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [copiedText, setCopiedText] = useState('');
  const [localRankedColleagues, setLocalRankedColleagues] = useState([]);
  const [connectionsFile, setConnectionsFile] = useState(null);
  const [uploadingConnections, setUploadingConnections] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [githubUsername, setGithubUsername] = useState('');
  const [emailSending, setEmailSending] = useState({});
  const [emailSent, setEmailSent] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [resumeExperience, setResumeExperience] = useState([]);
  const [mergedExperience, setMergedExperience] = useState([]);
  const [resumeDragActive, setResumeDragActive] = useState(false);
  const [showAllVetters, setShowAllVetters] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  // Redux state
  const vetState = useSelector(state => state.vet) || {};
  const { 
    candidate, 
    colleagues, 
    rankedColleagues,
    connectionMatches,
    nonConnectionMatches,
    comparisonDone,
    companyEmployees,
    loading: reduxLoading, 
    error: reduxError,
    githubCollaborators,
    githubLoading,
    githubError,
    uploadingConnections: reduxUploadingConnections,
    uploadConnectionsError: reduxUploadConnectionsError,
    uploadConnectionsSuccess: reduxUploadConnectionsSuccess,
    processingResume: reduxProcessingResume,
    resumeExperience: reduxResumeExperience,
    resumeError: reduxResumeError,
    emailSending: reduxEmailSending,
    emailSent: reduxEmailSent,
    emailError: reduxEmailError
  } = vetState;

  // Clear state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearVetState());
    };
  }, [dispatch]);

  // Handle errors from Redux
  useEffect(() => {
    if (reduxError) {
      setError(reduxError);
      setIsProcessing(false);
    }
  }, [reduxError]);

  // Extract LinkedIn ID from username
  const extractLinkedinId = (username) => {
    // Remove any URL parts if present
    if (username.includes('linkedin.com/in/')) {
      const matches = username.match(/linkedin\.com\/in\/([^\/]+)/);
      return matches ? matches[1] : username;
    }
    return username;
  };

  // Copy text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(''), 2000);
  };

  // Handle drag events for file upload
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // Handle file drop
  const handleConnectionsDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      handleConnectionsFile(file);
    }
  };

  // Handle file selection
  const handleConnectionsUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      handleConnectionsFile(file);
    }
  };

  // Process the selected file
  const handleConnectionsFile = (file) => {
    if (file.name.endsWith('.csv')) {
      setConnectionsFile(file);
      uploadConnectionsFile(file);
    } else {
      setError('Please upload a CSV file for LinkedIn connections');
    }
  };

  // Upload the file to the server
  const uploadConnectionsFile = async (file) => {
    try {
      dispatch(clearConnectionsUploadState());
      const result = await dispatch(uploadLinkedinConnections(file)).unwrap();
      setConnectionSuccess('LinkedIn connections uploaded successfully. Your connections will be used to highlight overlapping contacts when searching for potential vetters.');
    } catch (err) {
      console.error('Connections upload error:', err);
      setError(err || 'Failed to upload connections');
    }
  };

  // Handle resume file drag events
  const handleResumeDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setResumeDragActive(true);
    } else if (e.type === 'dragleave') {
      setResumeDragActive(false);
    }
  };

  // Handle resume file drop
  const handleResumeDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setResumeDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (file.type === 'application/pdf') {
        setResumeFile(file);
        processResume(file);
      } else {
        setError('Please upload a PDF file');
      }
    }
  };

  // Handle resume file upload via button
  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setResumeFile(file);
      processResume(file);
    } else {
      setError('Please upload a PDF file');
    }
  };

  // Process the resume file
  const processResume = async (file) => {
    setResumeLoading(true);
    setError(null);

    try {
      dispatch(clearResumeState());
      const result = await dispatch(processResumeExperience(file)).unwrap();
      
      if (result.success && result.experience) {
        // Set local state from Redux result
        setResumeExperience(result.experience.experience || []);
        
        // If candidate data is already loaded, merge experiences
        if (candidate && candidate.experience) {
          const merged = mergeExperiences(candidate.experience, result.experience.experience || []);
          setMergedExperience(merged);
        }
      } else {
        setResumeExperience([]);
      }
    } catch (err) {
      console.error('Resume processing error:', err);
      setError(err || 'Failed to process resume');
      setResumeExperience([]);
    } finally {
      setResumeLoading(false);
    }
  };

  // Function to compare company names for similarity
  const areCompanySimilar = (company1, company2) => {
    if (!company1 || !company2) return false;
    
    // Convert to lowercase for comparison
    const c1 = company1.toLowerCase().trim();
    const c2 = company2.toLowerCase().trim();
    
    // Exact match
    if (c1 === c2) return true;
    
    // Check if one contains the other
    if (c1.includes(c2) || c2.includes(c1)) return true;
    
    // Simple similarity check - if more than 70% of characters match
    const longerName = c1.length > c2.length ? c1 : c2;
    const shorterName = c1.length > c2.length ? c2 : c1;
    
    // If the shorter name is very short, require more similarity
    if (shorterName.length < 4) return false;
    
    // Count matching characters
    let matchCount = 0;
    for (let i = 0; i < shorterName.length; i++) {
      if (longerName.includes(shorterName[i])) {
        matchCount++;
      }
    }
    
    return (matchCount / shorterName.length) > 0.7;
  };

  // Function to merge experiences from Aviato and resume
  const mergeExperiences = (aviatoExperience, resumeExperience) => {
    if (!aviatoExperience || !resumeExperience) return aviatoExperience || [];
    
    // Create a copy of the Aviato experience
    const mergedExperience = [...aviatoExperience];
    
    // For each resume experience, check if there's a similar company in Aviato experience
    resumeExperience.forEach(resumeExp => {
      const resumeCompany = resumeExp.company_name;
      
      // Find matching experience in Aviato data
      const matchIndex = mergedExperience.findIndex(aviatoExp => 
        areCompanySimilar(aviatoExp.company_name, resumeCompany)
      );
      
      // If match found, replace the description with resume description
      if (matchIndex !== -1) {
        mergedExperience[matchIndex] = {
          ...mergedExperience[matchIndex],
          description: resumeExp.description || mergedExperience[matchIndex].description,
          // Keep the original company name from Aviato for consistency
          // But add a flag to indicate this was enhanced with resume data
          enhanced_with_resume: true
        };
      } else {
        // If no match found, add the resume experience to the merged list
        mergedExperience.push({
          ...resumeExp,
          from_resume: true
        });
      }
    });
    
    return mergedExperience;
  };

  // Update mergedExperience when candidate or resumeExperience changes
  useEffect(() => {
    if (candidate && candidate.experience && resumeExperience.length > 0) {
      const merged = mergeExperiences(candidate.experience, resumeExperience);
      setMergedExperience(merged);
    } else if (candidate && candidate.experience) {
      setMergedExperience(candidate.experience);
    }
  }, [candidate, resumeExperience]);

  // Main workflow function
  const startVettingProcess = async () => {
    if (!linkedinUsername.trim()) {
      setError('Please enter a LinkedIn username');
      return;
    }

    setError(null);
    setSuccess(null);
    setProgress(0);
    setIsProcessing(true);
    
    try {
      // Step 1: Get candidate information
      const linkedinId = extractLinkedinId(linkedinUsername);
      const candidateResult = await dispatch(fetchCandidate(linkedinId)).unwrap();
      setProgress(20);
      
      // Step 2: If GitHub username is provided, fetch GitHub collaborators
      if (githubUsername.trim()) {
        await dispatch(fetchGithubCollaborators(githubUsername)).unwrap();
      }
      setProgress(40);
      
      // Step 3: Get ranked colleagues - use the candidate from the result
      if (candidateResult?.candidate?.id) {
        const rankedResult = await dispatch(fetchRankedColleagues(candidateResult.candidate.id)).unwrap();
        console.log("Ranked colleagues result:", rankedResult);
        
        // Store in local state
        if (rankedResult.colleagues && rankedResult.colleagues.length > 0) {
          setLocalRankedColleagues(rankedResult.colleagues);
          
          // Step 4: Compare with connections if we have ranked colleagues
          try {
            await dispatch(compareWithConnections(rankedResult.colleagues)).unwrap();
          } catch (compareError) {
            console.error("Error comparing with connections:", compareError);
            // Continue with the process even if comparison fails
          }
        }
        
        // Step 5: If no ranked colleagues, get company employees
        if (!rankedResult.colleagues || rankedResult.count === 0) {
            console.log("No ranked colleagues found, fetching company employees");
            console.log("!!!Candidate result:", candidateResult);
            if (candidateResult.candidate.vertex) {
                console.log('passed first if statement')
                const employeesResult = await dispatch(fetchCompanyEmployees(candidateResult.candidate.vertex)).unwrap();
                console.log("Company employees result:", employeesResult);
                setProgress(50);
                setSuccess('Company employees retrieved successfully');
                
                // Step 6: Get colleagues from company employees
                if (employeesResult.employees && Object.keys(employeesResult.employees).length > 0) {
                    console.log('passed step 4 if statement')
                    const aviatoIds = Object.values(employeesResult.employees).flatMap(company => company.employees.map(employee => employee.id));
                    console.log("!!!Aviato IDs:", aviatoIds);
                    const colleaguesResult = await dispatch(fetchColleagues({
                        aviatoIds: aviatoIds,
                        mainCandidateId: candidateResult.candidate.id
                    })).unwrap();
                    console.log("Colleagues result:", colleaguesResult);
                    setProgress(75);
                    setSuccess('Colleagues analyzed successfully');
                    
                    // Step 7: Get ranked colleagues again
                    const finalRankedResult = await dispatch(fetchRankedColleagues(candidateResult.candidate.id)).unwrap();
                    console.log("Final ranked colleagues result:", finalRankedResult);
                    setProgress(100);
                    setSuccess('Vetting process completed successfully');
                    
                    // For the final ranked colleagues call, update local state again
                    if (finalRankedResult && finalRankedResult.colleagues && finalRankedResult.colleagues.length > 0) {
                        setLocalRankedColleagues(finalRankedResult.colleagues);
                        
                        // Step 8: Compare with connections if we have ranked colleagues
                        try {
                          await dispatch(compareWithConnections(finalRankedResult.colleagues)).unwrap();
                        } catch (compareError) {
                          console.error("Error comparing with connections:", compareError);
                          // Continue with the process even if comparison fails
                        }
                    }
                } else {
                    setProgress(100);
                    setSuccess('No employees found for this company');
                }
            } else {
                setProgress(100);
                setSuccess('No company information available for this candidate');
            }
        } else {
          // If we already have ranked colleagues, skip to the end
          setProgress(100);
          setSuccess('Vetting process completed successfully');
        }
      } else {
        setError('Could not retrieve candidate ID from the response');
        setIsProcessing(false);
      }
    } catch (error) {
      console.error('Vetting process error:', error);
      setError(`Error during vetting process: ${error.message || error}`);
    } finally {
      setIsProcessing(false);
    }
  };

  // Add this function to handle sending reference emails
  const handleSendReferenceEmail = async (collaboratorId, collaboratorName, collaboratorEmail) => {
    try {
      // Check if email is available
      if (!collaboratorEmail || collaboratorEmail === 'N/A') {
        setSnackbarMessage('No email address available for this reference.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      
      // Generate unique IDs for candidate and reference
      const candidateId = `candidate_${Date.now()}`;
      const referenceId = `reference_github_${collaboratorId}_${Date.now()}`;
      
      // Update local state to show loading
      setEmailSending(prev => ({ ...prev, [collaboratorId]: true }));
      
      // Use Redux action
      await dispatch(sendReferenceEmail({
        name: collaboratorName || 'Reference',
        email: collaboratorEmail,
        candidateId,
        referenceId
      })).unwrap();
      
      // Update local state to show success
      setEmailSent(prev => ({ ...prev, [collaboratorId]: true }));
      setSnackbarMessage('Reference email sent successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error sending reference email:', err);
      setSnackbarMessage('Failed to send reference email: ' + err);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      // Update local state to stop loading
      setEmailSending(prev => ({ ...prev, [collaboratorId]: false }));
    }
  };

  // Add this function to handle closing the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Add this function to handle showing more vetters
  const handleShowMoreVetters = () => {
    setShowAllVetters(true);
  };

  // Add this function to handle showing less vetters
  const handleShowLessVetters = () => {
    setShowAllVetters(false);
  };

  console.log("Current Redux state:", {
    candidate,
    rankedColleagues,
    connectionMatches,
    nonConnectionMatches,
    comparisonDone,
    companyEmployees,
    reduxLoading,
    reduxError,
    githubCollaborators,
    githubLoading,
    githubError
  });

  return (
    <PageContainer>
      <motion.div variants={childVariants}>
        <Typography variant="h1" gutterBottom>
          AI Vetting Assistant
        </Typography>
        <Typography 
          variant="body1" 
          color="textSecondary" 
          textAlign="center" 
          sx={{ mb: 6, maxWidth: '600px', mx: 'auto' }}
        >
          Find the perfect professional connections to vouch for your candidate's skills and experience.
        </Typography>
      </motion.div>

      <Grid container spacing={4}>
        {/* Left Column - Input Section */}
        <Grid item xs={12} md={6}>
          <ContentCard
            tagIcon={<LinkedInIcon sx={{ color: theme.palette.primary.main }} />}
            tagText="Find Connections"
            title="Candidate Vetting"
          >
            <Box sx={{ mb: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter LinkedIn username (e.g., johndoe)"
                label="LinkedIn Profile"
                value={linkedinUsername}
                onChange={(e) => setLinkedinUsername(e.target.value)}
                InputProps={{
                  startAdornment: <LinkedInIcon sx={{ mr: 1, color: theme.palette.primary.main }} />,
                }}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                placeholder="Optional: Enter GitHub username (e.g., johndoe)"
                label="GitHub Profile (Optional)"
                value={githubUsername}
                onChange={(e) => setGithubUsername(e.target.value)}
                InputProps={{
                  startAdornment: <GitHubIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />,
                }}
                sx={{ mb: 3 }}
              />

              <Typography variant="h6" gutterBottom>
                Upload LinkedIn Connections (Optional)
              </Typography>

              <UploadBox
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleConnectionsDrop}
                onClick={() => document.getElementById('connections-upload').click()}
                $isDragActive={dragActive}
              >
                <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="body1" gutterBottom>
                  Drag & drop your LinkedIn connections CSV file here
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  or click to browse files
                </Typography>
                <input
                  type="file"
                  id="connections-upload"
                  accept=".csv"
                  onChange={handleConnectionsUpload}
                  style={{ display: 'none' }}
                />
                {connectionsFile && (
                  <Chip 
                    label={connectionsFile.name}
                    onDelete={() => setConnectionsFile(null)}
                    color="primary"
                    variant="outlined"
                  />
                )}
              </UploadBox>

              {connectionSuccess && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {connectionSuccess}
                </Alert>
              )}

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Upload Resume (Optional)
              </Typography>

              <UploadBox
                onDragEnter={handleResumeDrag}
                onDragLeave={handleResumeDrag}
                onDragOver={handleResumeDrag}
                onDrop={handleResumeDrop}
                onClick={() => document.getElementById('resume-upload').click()}
                $isDragActive={resumeDragActive}
              >
                <DescriptionIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="body1" gutterBottom>
                  Drag & drop candidate's resume (PDF)
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  or click to browse files
                </Typography>
                <input
                  type="file"
                  id="resume-upload"
                  accept=".pdf"
                  onChange={handleResumeUpload}
                  style={{ display: 'none' }}
                />
                {resumeFile && (
                  <Chip 
                    label={resumeFile.name}
                    onDelete={() => {
                      setResumeFile(null);
                      setResumeExperience([]);
                    }}
                    color="primary"
                    variant="outlined"
                  />
                )}
              </UploadBox>

              {resumeLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isProcessing || !linkedinUsername.trim()}
                  onClick={startVettingProcess}
                >
                  {isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Find Vetters"
                  )}
                </Button>
              </Box>
            </Box>
          </ContentCard>
        </Grid>

        {/* Right column - Results */}
        <Grid item xs={12} md={6}>
          <ContentCard
            tagIcon={<PersonIcon sx={{ color: theme.palette.primary.main }} />}
            tagText="Results"
            title={candidate ? `${candidate.full_name}'s Results` : "Results will appear here"}
          >
            {/* Rest of the right column content will be updated later */}
            {!candidate && !reduxLoading && (
              <Box sx={{ textAlign: 'center', py: 6 }}>
                <Typography color="textSecondary">
                  Enter a LinkedIn username to view potential vetters.
                </Typography>
              </Box>
            )}

            {reduxLoading && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                <CircularProgress size={40} />
                <Typography sx={{ mt: 2 }}>Loading candidate information...</Typography>
              </Box>
            )}

            {/* Candidate Information */}
            {candidate && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    mb: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Candidate Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        <strong>Name:</strong> {candidate.full_name}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>LinkedIn:</strong> {candidate.linkedin_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        <strong>Potential Vetters:</strong> {rankedColleagues?.length || 0}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Profile Status:</strong> {candidate.has_full_profile ? 'Complete' : 'Partial'}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  {/* Add Experience Section */}
                  {(mergedExperience.length > 0 || candidate.experience?.length > 0) && (
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="h6" gutterBottom>
                        <WorkIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Experience
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <List>
                        {(mergedExperience.length > 0 ? mergedExperience : candidate.experience).map((exp, index) => (
                          <ListItem key={index} alignItems="flex-start" sx={{ 
                            mb: 2, 
                            p: 2, 
                            borderRadius: 1, 
                            bgcolor: exp.enhanced_with_resume ? 'rgba(76, 175, 80, 0.08)' : 'transparent',
                            border: exp.enhanced_with_resume ? '1px solid rgba(76, 175, 80, 0.3)' : 'none'
                          }}>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                <WorkIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="subtitle1" fontWeight="medium">
                                  {exp.title} at {exp.company_name}
                                  {exp.enhanced_with_resume && (
                                    <Chip 
                                      label="Enhanced" 
                                      size="small" 
                                      color="success" 
                                      variant="outlined" 
                                      sx={{ ml: 1, height: 20 }} 
                                    />
                                  )}
                                  {exp.from_resume && (
                                    <Chip 
                                      label="From Resume" 
                                      size="small" 
                                      color="info" 
                                      variant="outlined" 
                                      sx={{ ml: 1, height: 20 }} 
                                    />
                                  )}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Typography variant="body2" color="text.secondary">
                                    {exp.start_date} - {exp.end_date || 'Present'}
                                  </Typography>
                                  <Typography variant="body2" sx={{ mt: 1 }}>
                                    {exp.description || 'No description available'}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Paper>
              </motion.div>
            )}

            {/* Connection Matches Section */}
            {comparisonDone && connectionMatches.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    mb: 3,
                    borderLeft: '4px solid',
                    borderLeftColor: 'success.main',
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Your LinkedIn Connections ({connectionMatches.length})
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    These potential vetters are already in your LinkedIn network. They may be more likely to respond to your vetting requests.
                  </Typography>
                  {(showAllVetters ? connectionMatches : connectionMatches.slice(0, 10)).map((colleague, index) => (
                    <ColleagueCard key={index} colleague={colleague} isConnection={true} />
                  ))}
                  
                  {/* Show More/Less Button */}
                  {connectionMatches.length > 10 && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                      <Button 
                        variant="outlined" 
                        onClick={showAllVetters ? handleShowLessVetters : handleShowMoreVetters}
                        startIcon={showAllVetters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      >
                        {showAllVetters ? `Show Less` : `Show ${connectionMatches.length - 10} More`}
                      </Button>
                    </Box>
                  )}
                </Paper>
              </motion.div>
            )}
            
            {/* Other Potential Vetters Section */}
            {comparisonDone && nonConnectionMatches.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    mb: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Other Potential Vetters ({nonConnectionMatches.length})
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    These potential vetters are not in your LinkedIn network but may still be able to provide references for this candidate.
                  </Typography>
                  {(showAllVetters ? nonConnectionMatches : nonConnectionMatches.slice(0, 10)).map((colleague, index) => (
                    <ColleagueCard key={index} colleague={colleague} isConnection={false} />
                  ))}
                  
                  {/* Show More/Less Button */}
                  {nonConnectionMatches.length > 10 && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                      <Button 
                        variant="outlined" 
                        onClick={showAllVetters ? handleShowLessVetters : handleShowMoreVetters}
                        startIcon={showAllVetters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      >
                        {showAllVetters ? `Show Less` : `Show ${nonConnectionMatches.length - 10} More`}
                      </Button>
                    </Box>
                  )}
                </Paper>
              </motion.div>
            )}

            {/* Original Ranked Colleagues Section */}
            {!comparisonDone && (rankedColleagues?.length > 0 || localRankedColleagues.length > 0) && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    mb: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Potential Vetters ({rankedColleagues?.length || localRankedColleagues.length})
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    We found {rankedColleagues?.length || localRankedColleagues.length} potential vetters who may be able to provide references for this candidate.
                  </Typography>
                  {rankedColleagues && rankedColleagues.length > 0 ? (
                    <>
                      {(showAllVetters ? rankedColleagues : rankedColleagues.slice(0, 10)).map((colleague, index) => (
                        <ColleagueCard key={index} colleague={colleague} />
                      ))}
                      
                      {/* Show More/Less Button */}
                      {rankedColleagues.length > 10 && (
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                          <Button 
                            variant="outlined" 
                            onClick={showAllVetters ? handleShowLessVetters : handleShowMoreVetters}
                            startIcon={showAllVetters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          >
                            {showAllVetters ? `Show Less` : `Show ${rankedColleagues.length - 10} More`}
                          </Button>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography>No colleagues found</Typography>
                  )}
                </Paper>
              </motion.div>
            )}

            {/* GitHub Results Section */}
            {githubCollaborators.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: 2,
                    background: theme.palette.background.paper,
                    mb: 3,
                    borderLeft: '4px solid',
                    borderLeftColor: 'grey.800',
                  }}
                >
                  <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <GitHubIcon />
                    GitHub Collaborators ({githubCollaborators.length})
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    People who have collaborated with {githubUsername} on GitHub projects
                  </Typography>
                  <Grid container spacing={2}>
                    {githubCollaborators.map((collaborator, index) => (
                      <Grid item xs={12} key={index}>
                        <Card 
                          elevation={0}
                          sx={{ 
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              {collaborator.name || "*" + collaborator.username}
                            </Typography>
                            {collaborator.company && (
                              <Typography variant="body2" color="text.secondary">
                                <WorkIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                {collaborator.company}
                              </Typography>
                            )}
                            <Typography color="textSecondary" sx={{ mt: 2, mb: 1 }}>
                              Email: {collaborator.email || 'N/A'}
                            </Typography>
                            {collaborator.location && (
                              <Typography variant="body2" color="text.secondary">
                                <LocationOn sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                {collaborator.location}
                              </Typography>
                            )}
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              Contributions: {collaborator.contributions}
                            </Typography>
                            <Typography variant="body2">
                              Repositories: {collaborator.repositories.join(', ')}
                            </Typography>
                            <Box sx={{ display: 'flex', mt: 1, gap: 1 }}>
                              <Button
                                variant="text"
                                href={collaborator.profile_url}
                                target="_blank"
                                startIcon={<LinkIcon />}
                              >
                                View Profile
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </motion.div>
            )}
            
          </ContentCard>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default VettingPage2;
