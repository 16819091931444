import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../services/authSlice';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Divider,
  Container,
  Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { colors } from './IntratalentTheme';

// Styled components
const NavContainer = styled(Container)(({ theme }) => ({
  maxWidth: '67.5625rem',
  border: `1px solid ${colors.grayDark}`,
  backgroundColor: colors.black2,
  borderRadius: '3.25rem',
  padding: '.5rem .5rem .5rem 1.125rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Logo = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
}));

const LogoText = styled('div')(({ theme }) => ({
  marginLeft: '0.5rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MenuList = styled('ul')(({ theme }) => ({
  display: 'flex',
  listStyle: 'none',
  gap: '1rem',
  padding: 0,
  margin: 0,
}));

const MenuLink = styled(Link)(({ theme, active }) => ({
  textDecoration: 'none',
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 500,
  padding: '0.5rem',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const NavButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const LoginButton = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  padding: '0.5rem 1rem',
  borderRadius: '3.0625rem',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const MobileDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    maxWidth: '300px',
    backgroundColor: colors.black2,
    padding: '1rem',
  },
}));

const MobileMenuLink = styled(Link)(({ theme, active }) => ({
  textDecoration: 'none',
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  fontSize: '1.2rem',
  fontWeight: 500,
  padding: '1rem 0',
  display: 'block',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const IntratalentNavbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const isActive = (path) => location.pathname === path;
  
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  
  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    setDrawerOpen(false);
    navigate('/');
  };
  
  const navItems = [
    { label: 'Team', path: '/#team-section' },
    { label: 'Demo', path: '/demo' },
    { label: 'Vetting', path: '/vetting' },
    { label: 'Dashboard', path: '/dashboard' },
  ];
  
  return (
    <AppBar position="fixed" sx={{ 
      backgroundColor: 'transparent', 
      boxShadow: 'none',  // This already removes one shadow
      padding: '1rem 0',
      background: 'none',  // This ensures no background color
      border: 'none'      // This ensures no border
    }}>
      <Toolbar disableGutters>
        <NavContainer>
          <Logo to="/">
            <img src="/landing/images/logo.png" alt="Intratalent Logo" width="28" height="28" />
            <LogoText>Intratalent</LogoText>
          </Logo>
          
          <NavLinks>
            <MenuList>
              {navItems.map((item, index) => (
                <li key={index}>
                  <MenuLink 
                    to={item.path} 
                    active={isActive(item.path) ? 1 : 0}
                  >
                    {item.label}
                  </MenuLink>
                </li>
              ))}
            </MenuList>
          </NavLinks>
          
          <NavButtons>
            {token ? (
              <>
                <IconButton onClick={handleMenu} color="inherit">
                  {user?.avatar ? (
                    <Avatar src={user.avatar} alt={user.name} sx={{ width: 32, height: 32 }} />
                  ) : (
                    <AccountCircleIcon />
                  )}
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      backgroundColor: colors.black3,
                      border: `1px solid ${colors.grayDark}`,
                      borderRadius: '1rem',
                      overflow: 'visible',
                      mt: 1.5,
                    },
                  }}
                >
                  <MenuItem onClick={() => { handleClose(); navigate('/dashboard'); }}>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <LoginButton to="/login">Login</LoginButton>
                <Button 
                  component={Link} 
                  to="/signup" 
                  variant="outlined"
                  sx={{ padding: '0.5rem 1rem', height: 'auto' }}
                >
                  Create an account
                </Button>
              </>
            )}
          </NavButtons>
          
          <MobileMenuButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </MobileMenuButton>
        </NavContainer>
      </Toolbar>
      
      {/* Mobile Drawer */}
      <MobileDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <IconButton onClick={handleDrawerToggle} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Divider sx={{ mb: 2 }} />
        
        <List>
          {navItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <MobileMenuLink 
                to={item.path} 
                onClick={handleDrawerToggle}
                active={isActive(item.path) ? 1 : 0}
              >
                {item.label}
              </MobileMenuLink>
            </ListItem>
          ))}
        </List>
        
        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ mt: 2 }}>
          {token ? (
            <>
              <Button 
                fullWidth 
                variant="contained" 
                onClick={() => { handleDrawerToggle(); navigate('/dashboard'); }}
                sx={{ mb: 1 }}
              >
                Dashboard
              </Button>
              <Button 
                fullWidth 
                variant="outlined" 
                onClick={handleLogout}
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button 
                fullWidth 
                component={Link} 
                to="/login" 
                variant="contained"
                onClick={handleDrawerToggle}
                sx={{ mb: 1 }}
              >
                Login
              </Button>
              <Button 
                fullWidth 
                component={Link} 
                to="/signup" 
                variant="outlined"
                onClick={handleDrawerToggle}
              >
                Create an account
              </Button>
            </>
          )}
        </Box>
      </MobileDrawer>
    </AppBar>
  );
};

export default IntratalentNavbar; 