import axios from 'axios';
import { handleLogout } from './authActions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add interceptor to add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

// Auth endpoints
export const login = async (credentials) => {
  const response = await api.post('/auth/login', credentials);
  return response.data;
};

export const signup = async (credentials) => {
  const response = await api.post('/auth/signup', credentials);
  return response.data;
};

export const verifyEmail = async (token) => {
  const response = await api.get(`/auth/verify-email/${token}`);
  return response.data;
};

export const verifyToken = async () => {
  const response = await api.get('/auth/verify');
  return response.data;
};

// Listings endpoints
export const getListings = async () => {
  const response = await api.get('/listings/list');
  return response.data;
};

export const handleListingCreation = async (paymentData) => {
  try {
    console.log('Creating payment intent with data:', paymentData);
    const response = await api.post('/payments/create-payment-intent', paymentData);
    
    // If amount is 0, we don't need a client secret
    if (response.data.amount === 0) {
      return {
        clientSecret: null,
        amount: 0,
        listingData: paymentData.listing_data,
        isFree: true
      };
    }
    
    return {
      clientSecret: response.data.clientSecret,
      amount: response.data.amount,
      listingData: paymentData.listing_data,
      isFree: false
    };
  } catch (error) {
    console.error('Payment Intent Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to initiate payment';
  }
};

export const updateListing = async (listingId, listingData) => {
  const response = await api.put(`/listings/update/${listingId}`, listingData);
  return response.data;
};

export const deleteListing = async (listingId) => {
  const response = await api.delete(`/listings/delete/${listingId}`);
  return response.data;
};

export const getListing = async (listingId) => {
  const response = await api.get(`/listings/get/${listingId}`);
  return response.data;
};

// Create payment intent
export const createPaymentIntent = async () => {
  const response = await api.post('/payments/create-payment-intent');
  return response.data;
};

export const validatePromoCode = async (promoCode) => {
  const response = await api.post('/payments/validate-promo-code', { promo_code: promoCode });
  return response.data;
};

// Credit management endpoints
export const getCreditBalance = async () => {
  const response = await api.get('/payments/get-credit-balance');
  return response.data;
};

export const getCreditsPricing = async () => {
  const response = await api.get('/payments/get-credits-pricing');
  return response.data;
};

export const createCreditPayment = async (numCredits) => {
  try {
    const response = await api.post('/payments/create-credits-payment', {
      num_credits: numCredits
    });
    return {
      clientSecret: response.data.clientSecret,
      amount: response.data.amount,
      num_credits: response.data.num_credits
    };
  } catch (error) {
    console.error('Credit Payment Intent Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to initiate credit purchase';
  }
};

export const confirmCreditPayment = async (paymentIntentId) => {
  const response = await api.post('/payments/credits-payment-success', {
    payment_intent_id: paymentIntentId
  });
  return response.data;
};

// Confirm payment and create listing
export const confirmPaymentAndCreateListing = async (paymentIntentId, listingData) => {
  // If paymentIntentId is 'free', use the free listing endpoint
  if (paymentIntentId === 'free') {
    return createFreeListing(listingData);
  }
  
  const response = await api.post('/payments/payment-success', {
    payment_intent_id: paymentIntentId,
    listing_data: listingData
  });
  return response.data;
};

// Resume processing endpoints
export const generateWeights = async (query) => {
  try {
    const response = await api.post('/resume/generate_weights', { query });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while generating weights';
  }
};

export const searchProjects = async (query, weights = null, listingId = null, minGpa = null, logForFinetuning = false) => {
  try {
    const requestBody = {
      query,
      ...(weights && { weights }),
      ...(minGpa !== null && { min_gpa: minGpa }),
      ...(logForFinetuning && { log_for_finetuning: logForFinetuning })
    };
    
    // Use demo route if no listingId is provided
    const endpoint = listingId ? '/resume/search' : '/resume/search_demo';
    
    if (listingId) {
      requestBody.listing_id = listingId;
    }
    
    console.log('Search request:', requestBody);
    
    const response = await api.post(endpoint, requestBody);
    return response.data;
  } catch (error) {
    console.error('Search error:', error.response?.data || error.message);
    throw error.response?.data?.error || 'An error occurred while searching';
  }
};

export const processResume = async (file, githubUsername, listingId) => {
  try {
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('github_username', githubUsername);
    formData.append('listing_id', listingId);

    console.log('Sending request with:', {
      file: file.name,
      githubUsername,
      listingId
    });
    
    const response = await api.post('/resume/process_resume', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || error.message || 'An error occurred while processing the resume');
  }
};

// Add this new function for free listings
export const createFreeListing = async (listingData) => {
  try {
    const response = await api.post('/listings/create_free', listingData);
    return response.data;
  } catch (error) {
    console.error('Free Listing Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to create free listing';
  }
};

// ATS endpoints
export const createAtsLinkToken = async () => {
  const response = await api.post('/ats/create-link-token');
  return response.data;
};

export const exchangeAtsToken = async (public_token) => {
  const response = await api.post('/ats/exchange-token', { public_token });
  return response.data;
};

export const syncAtsData = async () => {
  const response = await api.post('/ats/sync-data');
  return response.data;
};

// Google auth endpoints
export const googleWorkspaceSignup = async (token) => {
  const response = await api.post('/auth/google/create-workspace', {
    token
  });
  return response.data;
};

export const googleLogin = async (token) => {
  const response = await api.post('/auth/google/login', { token });
  return response.data;
};

// Vetting endpoints
export const getCandidate = async (linkedinId) => {
  try {
    console.log('Fetching candidate with LinkedIn ID:', linkedinId);
    const response = await api.post('/vet/get-candidate', { linkedin_id: linkedinId });
    console.log('Candidate response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching candidate:', error.response?.data);
    throw error.response?.data?.error || 'Failed to retrieve candidate information';
  }
};

// Additional vetting endpoints
export const getCompanyEmployees = async (vertex) => {
  try {
    console.log('getting company employees for vertex: ', vertex)
    const response = await api.post('/vet/get-company-employees', { vertex });
    console.log('company employees response: ', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching company employees:', error.response?.data);
    throw error.response?.data?.error || 'Failed to retrieve company employees';
  }
};

export const getColleagues = async (aviatoIds, mainCandidateId) => {
  try {
    console.log('getting colleagues for aviato ids: ', aviatoIds, ' and main candidate id: ', mainCandidateId)
    const response = await api.post('/vet/get-colleagues', { 
      aviato_ids: aviatoIds,
      main_candidate_id: mainCandidateId
    });
    console.log('colleagues response: ', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching colleagues:', error.response?.data);
    throw error.response?.data?.error || 'Failed to retrieve colleagues';
  }
};

export const getRankedColleagues = async (aviatoId) => {
  try {
    console.log('getting ranked colleagues for aviato id: ', aviatoId)
    const response = await api.post('/vet/get-ranked-colleagues', { aviato_id: aviatoId });
    console.log('ranked colleagues response: ', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching ranked colleagues:', error.response?.data);
    throw error.response?.data?.error || 'Failed to retrieve ranked colleagues';
  }
};

export const compareColleaguesWithConnections = async (colleagues) => {
  try {
    console.log('comparing colleagues with connections: ', colleagues.length);
    const response = await api.post('/linkedin/compare-colleagues', { colleagues });
    console.log('comparison response: ', response.data);
    return response.data;
  } catch (error) {
    console.error('Error comparing colleagues with connections:', error.response?.data);
    throw error.response?.data?.error || 'Failed to compare colleagues with connections';
  }
};

export const getPersonContactInfo = async (aviatoId) => {
  try {
    console.log('getting person contact info for aviato id: ', aviatoId)
    const response = await api.post('/vet/get-contact-info', { aviato_id: aviatoId });
    console.log('person contact info response: ', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching person contact info:', error.response?.data);
    throw error.response?.data?.error || 'Failed to retrieve person contact info';
  }
};

export const getGithubCollaborators = async (github_username) => {
  try {
    const response = await api.post('/github/collaborators', { github_username });
    return response.data;
  } catch (error) {
    console.error('Error fetching GitHub collaborators:', error.response?.data);
    throw error.response?.data?.error || 'Failed to fetch GitHub collaborators';
  }
};

// Vetting credit management endpoints
export const getVettingCreditBalance = async () => {
  const response = await api.get('/payments/get-vetting-credit-balance');
  return response.data;
};

export const getVettingCreditsPricing = async () => {
  const response = await api.get('/payments/get-vetting-credits-pricing');
  return response.data;
};

export const createVettingCreditPayment = async (numCredits) => {
  try {
    const response = await api.post('/payments/create-vetting-credits-payment', {
      num_credits: numCredits
    });
    return {
      clientSecret: response.data.clientSecret,
      amount: response.data.amount,
      num_credits: response.data.num_credits
    };
  } catch (error) {
    console.error('Vetting Credit Payment Intent Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to initiate vetting credit purchase';
  }
};

export const confirmVettingCreditPayment = async (paymentIntentId) => {
  const response = await api.post('/payments/vetting-credits-payment-success', {
    payment_intent_id: paymentIntentId
  });
  return response.data;
};

export default api;
