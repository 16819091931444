import React from 'react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

// Main container with background and stars decoration
const StyledPageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
}));

// Stars decoration image (left)
const StarsLeft = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '-30%',
  top: 0,
  pointerEvents: 'none',
  transform: 'rotate(45deg)',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    left: '-50%',
  },
}));

// Stars decoration image (right)
const StarsRight = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: '-26%',
  top: 0,
  pointerEvents: 'none',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    right: '-50%',
  },
}));

// Blue decoration element
const BlueDecoration = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '50%',
  height: '50%',
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(41, 127, 255, 0.1) 0%, rgba(41, 127, 255, 0) 100%)',
  pointerEvents: 'none',
  zIndex: 0,
}));

// Main content with proper spacing for navbar
const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: '7rem',
  paddingBottom: '2rem',
  zIndex: 1,
  position: 'relative',
}));

// Interior container with proper width constraints
const ContentContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '150rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },
}));

// Animation variants for fade-in effects
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      duration: 0.6,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.4 }
  }
};

/**
 * PageContainer component that applies the WebFlow styling to pages
 * @param {object} props Component props
 * @param {ReactNode} props.children Content to display inside the container
 * @param {boolean} props.noAnimation Disable animations if true
 * @param {string} props.className Additional CSS class names
 * @param {object} props.contentSx Additional MUI sx props for the content container
 */
const PageContainer = ({ children, noAnimation = false, className, contentSx = {} }) => {
  const content = (
    <StyledPageWrapper className={className}>
      {/* Background decorations */}
      <StarsLeft 
        src="/landing/images/home-stars-decoration.png" 
        alt=""
        srcSet="/landing/images/home-stars-decoration-p-500.png 500w, /landing/images/home-stars-decoration.png 778w"
        sizes="(max-width: 778px) 100vw, 778px"
      />
      <StarsRight 
        src="/landing/images/home-stars-decoration.png" 
        alt=""
        srcSet="/landing/images/home-stars-decoration-p-500.png 500w, /landing/images/home-stars-decoration.png 778w"
        sizes="(max-width: 778px) 100vw, 778px" 
      />
      <BlueDecoration />
      
      {/* Main content */}
      <MainContent>
        <ContentContainer sx={contentSx}>
          {children}
        </ContentContainer>
      </MainContent>
    </StyledPageWrapper>
  );
  
  // Apply animation if enabled
  if (noAnimation) {
    return content;
  }
  
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {content}
    </motion.div>
  );
};

// Export the motion variants for use in child components
export { childVariants };

export default PageContainer; 