import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Card,
  CardContent,
  Grid,
  Avatar,
  Chip,
  CircularProgress,
  Alert,
  useTheme,
  Rating,
  Divider,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip as MuiTooltip,
} from '@mui/material';
import { motion } from 'framer-motion';
import { 
  Work, 
  Person, 
  Link, 
  Phone, 
  History,
  GitHub,
  CheckCircleOutline, 
  TrendingUp,
  Warning,
  LocationOn,
  Upload,
  CloudUpload,
  Star,
  Email,
  Info,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { processVetting } from '../../services/api';
import axios from 'axios';
import { colors } from '../common/IntratalentTheme';
import PageContainer from '../common/PageContainer';
import ContentCard from '../common/ContentCard';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    '&:hover fieldset': {
      borderColor: colors.blue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.blue,
    },
  },
  '& .MuiInputLabel-root': {
    color: colors.grayLight,
  },
  '& .MuiOutlinedInput-input': {
    color: colors.white,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '3.0625rem',
  textTransform: 'none',
  fontWeight: 600,
  padding: '0.75rem 1.5rem',
  transition: 'all 0.3s',
  height: 56,
  boxShadow: `0 0 24px 10px ${colors.blue}33`,
  backgroundColor: colors.blueDark,
  border: `1px solid ${colors.blue}`,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.blue,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '20px',
  backgroundColor: colors.black2,
  border: `1px solid ${colors.grayDark}`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
  marginBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: colors.black3,
  border: `1px solid ${colors.grayDark}30`,
  borderRadius: '16px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  marginBottom: theme.spacing(2),
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: `0 10px 25px rgba(0, 0, 0, 0.4), 0 0 10px ${colors.blue}33`,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const UploadZone = styled(Paper)(({ theme, isDragActive }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  border: `2px dashed ${isDragActive ? colors.blue : colors.grayDark}`,
  backgroundColor: isDragActive ? 'rgba(41, 127, 255, 0.1)' : colors.black3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
}));

const VettingPage = () => {
  const theme = useTheme();
  const [jobTitle, setJobTitle] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [githubUsername, setGithubUsername] = useState('');
  const [githubResults, setGithubResults] = useState(null);
  const [githubLoading, setGithubLoading] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [experience, setExperience] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const [calling, setCalling] = useState({});
  const [success, setSuccess] = useState(null);
  const [transcripts, setTranscripts] = useState([]);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [transcriptDialogOpen, setTranscriptDialogOpen] = useState(false);
  const [loadingTranscripts, setLoadingTranscripts] = useState(false);
  const [connectionsFile, setConnectionsFile] = useState(null);
  const [uploadingConnections, setUploadingConnections] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [sharedConnections, setSharedConnections] = useState([]);
  const [otherCandidates, setOtherCandidates] = useState([]);
  const [emailSending, setEmailSending] = useState({});
  const [emailSent, setEmailSent] = useState({});

  // Fetch transcripts on component mount
  useEffect(() => {
    fetchTranscripts();
  }, []);

  const fetchTranscripts = async () => {
    setLoadingTranscripts(true);
    try {
      const response = await fetch('http://localhost:5001/call/transcripts');
      const data = await response.json();
      if (data.success) {
        setTranscripts(data.transcripts);
      } else {
        console.error('Failed to fetch transcripts:', data.error);
      }
    } catch (err) {
      console.error('Error fetching transcripts:', err);
    } finally {
      setLoadingTranscripts(false);
    }
  };

  const handleViewTranscript = async (callSid) => {
    try {
      const response = await fetch(`http://localhost:5001/call/transcript/${callSid}`);
      const data = await response.json();
      if (data.success) {
        setSelectedTranscript(data.transcript);
        setTranscriptDialogOpen(true);
      } else {
        setError('Failed to fetch transcript details');
      }
    } catch (err) {
      setError('Error fetching transcript details');
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResults(null);
    setSharedConnections([]);
    setOtherCandidates([]);

    try {
      const response = await fetch('http://localhost:5001/vet/search-similar-candidates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linkedin_url: linkedinUrl,
          target_job_title: jobTitle
        })
      });
      
      const data = await response.json();
      console.log('Vetting response:', data);
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to process request');
      }
      
      if (data.success && data.search_results) {
        // Process the results to separate shared connections and other candidates
        const searchResults = data.search_results;
        const connectionUsernames = searchResults.connection_usernames || [];
        
        // Filter candidates into shared connections and others
        const shared = [];
        const others = [];
        
        searchResults.similar_candidates.forEach(candidate => {
          const username = candidate.linkedin_username;
          if (username && connectionUsernames.includes(username)) {
            shared.push(candidate);
          } else {
            others.push(candidate);
          }
        });
        
        setSharedConnections(shared);
        setOtherCandidates(others);
        setResults(data.search_results);
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('Vetting error:', err);
      setError(err.message || 'Failed to process request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCall = async () => {
    try {
      // First initiate the call
      const callResponse = await fetch('http://localhost:5001/call/initiate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: '+18049379667', // Hardcoded number as requested
          candidate_id: linkedinUrl, // Using LinkedIn URL as candidate ID
          reference_id: 'direct_reference' // Using a default reference ID for direct calls
        })
      });
      
      const callData = await callResponse.json();
      if (!callResponse.ok) {
        throw new Error(callData.error || 'Failed to initiate call');
      }

      // Then send the email
      const emailResponse = await fetch('http://localhost:5001/email/send-template-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'Rishi',
          email: 'rishi.sadanandan25@gmail.com',
          template: 'reference_request',
          data: {
            phoneNumber: '804-937-9667'
          }
        })
      });

      const emailData = await emailResponse.json();
      if (!emailResponse.ok) {
        throw new Error(emailData.error || 'Failed to send email');
      }
      
      // Show success message
      setSuccess('Reference check call initiated and email sent successfully');
    } catch (err) {
      setError('Failed to initiate reference check: ' + err.message);
    }
  };

  const handleGithubSearch = async () => {
    if (!githubUsername) {
      setError('Please enter a GitHub username');
      return;
    }

    setGithubLoading(true);
    setError(null);

    try {
      const response = await fetch('http://localhost:5001/github/collaborators', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          github_username: githubUsername
        })
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch GitHub data');
      }
      
      if (data.success) {
        setGithubResults(data.collaborators);
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('GitHub search error:', err);
      setError(err.message || 'Failed to fetch GitHub data. Please try again.');
    } finally {
      setGithubLoading(false);
    }
  };

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setResumeFile(file);
      await processResume(file);
    } else {
      setError('Please upload a PDF file');
    }
  };

  const processResume = async (file) => {
    setResumeLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append('resume', file);

      const response = await fetch('http://localhost:5001/resume/extract-experience', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to process resume');
      }

      if (data.success && data.experience) {
        // The experience data is already parsed JSON with an experience array
        setExperience(data.experience.experience || []);
      } else {
        setExperience([]);
      }
    } catch (err) {
      console.error('Resume processing error:', err);
      setError(err.message || 'Failed to process resume');
      setExperience([]);
    } finally {
      setResumeLoading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleConnectionsDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      handleConnectionsFile(file);
    }
  };

  const handleConnectionsUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      handleConnectionsFile(file);
    }
  };

  const handleConnectionsFile = (file) => {
    if (file.name.endsWith('.csv')) {
      setConnectionsFile(file);
      uploadConnectionsFile(file);
    } else {
      setError('Please upload a CSV file for LinkedIn connections');
    }
  };

  const uploadConnectionsFile = async (file) => {
    setUploadingConnections(true);
    setError(null);
    setConnectionSuccess(null);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('http://localhost:5001/linkedin/upload-connections', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to upload connections');
      }

      setConnectionSuccess('LinkedIn connections uploaded successfully. Your connections will be used to highlight overlapping contacts when searching for similar candidates.');
    } catch (err) {
      console.error('Connections upload error:', err);
      setError(err.message || 'Failed to upload connections');
    } finally {
      setUploadingConnections(false);
    }
  };

  const handleSendReferenceEmail = async (vetterId, vetterName, vetterEmail) => {
    try {
      // Check if email is available
      if (!vetterEmail || vetterEmail === 'example@example.com') {
        setError('No email address available for this reference. Please get contact info first.');
        return;
      }
      
      // Update state to show loading
      setEmailSending(prev => ({ ...prev, [vetterId]: true }));
      
      // Generate unique IDs for candidate and reference
      const candidateId = `candidate_${Date.now()}`;
      const referenceId = `reference_${vetterId}_${Date.now()}`;
      
      // Send email to reference
      const response = await axios.post(`${API_BASE_URL}/email/send-template-email`, {
        name: vetterName || 'Reference',
        email: vetterEmail,
        template: 'reference_request',
        data: {
          candidate: 'the candidate', // Use a generic name since we don't have the candidate's name
          previous_job: 'their previous company',
          new_role: 'the position',
          candidate_id: candidateId,
          reference_id: referenceId
        }
      });
      
      if (response.data.success) {
        // Update state to show success
        setEmailSent(prev => ({ ...prev, [vetterId]: true }));
        setSuccess('Reference email sent successfully');
      } else {
        throw new Error(response.data.error || 'Failed to send email');
      }
    } catch (err) {
      console.error('Error sending reference email:', err);
      setError('Failed to send reference email: ' + err.message);
    } finally {
      // Update state to stop loading
      setEmailSending(prev => ({ ...prev, [vetterId]: false }));
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const TranscriptDialog = ({ open, onClose, transcript }) => {
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [error, setError] = useState(null);

    const triggerAnalysis = async () => {
      setIsAnalyzing(true);
      setError(null);
      try {
        const response = await fetch(`http://localhost:5001/call/analyze/${transcript.call_sid}`, {
          method: 'POST'
        });
        const data = await response.json();
        if (data.success) {
          // Update the transcript with the new analysis
          transcript.analysis = data.analysis;
        } else {
          setError(data.error || 'Failed to generate analysis');
        }
      } catch (err) {
        setError('Error generating analysis');
        console.error('Error:', err);
      } finally {
        setIsAnalyzing(false);
      }
    };

    const renderBulletPoints = (content) => {
      if (!content) return null;
      return content.split('\n').map((line, i) => (
        <Typography 
          key={i} 
          sx={{ 
            ml: 2, 
            mb: 1,
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1
          }}
        >
          {line.startsWith('•') ? (
            <>
              <span style={{ color: 'primary.main' }}>•</span>
              {line.substring(1).trim()}
            </>
          ) : line}
        </Typography>
      ));
    };

    const renderSection = (title, content, icon, color = 'primary') => {
      if (!content) return null;
      return (
        <Box sx={{ mb: 3 }}>
          <Typography 
            variant="h6" 
            color={color} 
            gutterBottom 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              fontSize: '1.1rem',
              fontWeight: 600
            }}
          >
            {icon} {title}
          </Typography>
          {renderBulletPoints(content)}
        </Box>
      );
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Reference Check Analysis</DialogTitle>
        <DialogContent>
          {transcript?.analysis ? (
            <Box sx={{ mt: 2 }}>
              {/* Summary section with special styling */}
              <Box sx={{ 
                mb: 3, 
                p: 2, 
                bgcolor: 'primary.light', 
                color: 'primary.contrastText',
                borderRadius: 1
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 600
                  }}
                >
                  📝 Summary
                </Typography>
                <Typography>{transcript.analysis.summary}</Typography>
              </Box>

              {renderSection('Key Points Discussed', transcript.analysis.key_points, '🎯')}
              {renderSection('Strengths Highlighted', transcript.analysis.strengths, '💪')}
              {renderSection('Areas for Development', transcript.analysis.development_areas, '📈')}
              
              {/* Notable Quotes with special styling */}
              {transcript.analysis.notable_quotes && (
                <Box sx={{ mb: 3 }}>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1,
                      fontSize: '1.1rem',
                      fontWeight: 600
                    }}
                  >
                    💬 Notable Quotes
                  </Typography>
                  <Box sx={{ 
                    mt: 1,
                    p: 2, 
                    bgcolor: 'grey.100',
                    borderRadius: 1,
                    borderLeft: '4px solid',
                    borderColor: 'primary.main'
                  }}>
                    {renderBulletPoints(transcript.analysis.notable_quotes)}
                  </Box>
                </Box>
              )}

              {/* Recommendation with special styling */}
              <Box sx={{ 
                mt: 4,
                p: 2, 
                bgcolor: 'success.light',
                color: 'success.contrastText',
                borderRadius: 1
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 600
                  }}
                >
                  🎯 Recommendation
                </Typography>
                <Typography>{transcript.analysis.recommendation}</Typography>
              </Box>

              <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                Analysis generated on: {new Date(transcript.analysis.analyzed_at).toLocaleString()}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography color="text.secondary" paragraph>
                Analysis not available yet.
              </Typography>
              <Button
                variant="contained"
                onClick={triggerAnalysis}
                disabled={isAnalyzing}
                startIcon={isAnalyzing ? <CircularProgress size={20} /> : null}
              >
                {isAnalyzing ? 'Generating Analysis...' : 'Generate Analysis'}
              </Button>
              {error && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              )}
            </Box>
          )}

          <Divider sx={{ my: 3 }} />
          
          <Typography variant="h6" gutterBottom>Conversation History</Typography>
          <List>
            {transcript?.conversation_history?.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  bgcolor: msg.speaker === 'ai' ? 'action.hover' : 'background.paper',
                  borderRadius: 1,
                  mb: 1
                }}>
                <ListItemText
                  primary={msg.speaker === 'ai' ? 'AI' : 'Reference'}
                  secondary={msg.text}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <PageContainer>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
          >
            Find Your Perfect Vetters
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 4, maxWidth: '700px', mx: 'auto' }}>
            Get matched with the best people to vet your candidacy based on experience and expertise
          </Typography>
        </Box>

        <StyledPaper>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
                  <StyledTextField
                    fullWidth
                    label="Job Title"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <StyledTextField
                    fullWidth
                    label="LinkedIn URL"
                    value={linkedinUrl}
                    onChange={(e) => setLinkedinUrl(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <StyledButton
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    Search Similar Candidates
                  </StyledButton>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ mb: 4 }}>
                  <StyledTextField
                    fullWidth
                    label="GitHub Username"
                    value={githubUsername}
                    onChange={(e) => setGithubUsername(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <StyledButton
                    variant="contained"
                    onClick={handleGithubSearch}
                    disabled={githubLoading}
                    startIcon={githubLoading ? <CircularProgress size={20} /> : <GitHub />}
                    sx={{ bgcolor: colors.black3, '&:hover': { bgcolor: colors.black4 } }}
                  >
                    Search GitHub History
                  </StyledButton>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ mb: 4 }}>
                  <input
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    id="resume-file"
                    type="file"
                    onChange={handleResumeUpload}
                  />
                  <label htmlFor="resume-file">
                    <StyledButton
                      variant="contained"
                      component="span"
                      disabled={resumeLoading}
                      startIcon={resumeLoading ? <CircularProgress size={20} /> : <Upload />}
                      sx={{ width: '100%', mb: 2 }}
                    >
                      Upload Resume (PDF)
                    </StyledButton>
                  </label>
                  {resumeFile && (
                    <Typography variant="body2" color="textSecondary">
                      Selected: {resumeFile.name}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </StyledPaper>

        {/* LinkedIn Connections Upload Section */}
        <StyledPaper>
          <Box 
            sx={{ 
              mt: 3, 
              p: 3, 
              border: '2px dashed',
              borderColor: dragActive ? colors.blue : colors.grayDark,
              borderRadius: 2,
              bgcolor: dragActive ? 'rgba(41, 127, 255, 0.1)' : colors.black3,
              transition: 'all 0.3s ease',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleConnectionsDrop}
            onClick={() => document.getElementById('connections-file').click()}
          >
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id="connections-file"
              type="file"
              onChange={handleConnectionsUpload}
            />
            <CloudUpload sx={{ fontSize: 48, color: dragActive ? colors.blue : colors.grayDark, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Upload LinkedIn Connections
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Drag & drop your connections.csv file here or click to browse
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Export your LinkedIn connections from LinkedIn and upload the CSV file to highlight overlapping connections
            </Typography>
            {uploadingConnections && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
            {connectionsFile && !uploadingConnections && (
              <Chip 
                label={connectionsFile.name} 
                color="primary" 
                variant="outlined" 
                sx={{ mt: 2 }} 
              />
            )}
          </Box>

          {connectionSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {connectionSuccess}
            </Alert>
          )}

          {/* Error Display */}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </StyledPaper>

        {/* Work Experience Section */}
        {experience && (
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              <Work sx={{ mr: 1, verticalAlign: 'middle' }} />
              Work Experience
            </Typography>
            <Grid container spacing={2}>
              {experience.map((job, index) => (
                <Grid item xs={12} key={index}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant="h6">
                        {job.title}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        {job.company}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                        {job.start_date} - {job.end_date}
                      </Typography>
                      <Typography variant="body1">
                        {job.description}
                      </Typography>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        )}

        {/* GitHub Results */}
        {githubResults && (
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              <GitHub sx={{ mr: 1, verticalAlign: 'middle' }} />
              GitHub Collaborators
            </Typography>
            <Grid container spacing={2}>
              {githubResults.map((collaborator, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant="h6" gutterBottom>
                        {collaborator.name || 'Name not available'}
                        {collaborator.username && (
                          <Typography variant="subtitle1" color="textSecondary">
                            Username: {collaborator.username}
                          </Typography>
                        )}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        {collaborator.company && (
                          <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                            <Work sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                            {collaborator.company}
                          </Typography>
                        )}
                        {collaborator.location && (
                          <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                            <LocationOn sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                            {collaborator.location}
                          </Typography>
                        )}
                      </Box>
                      <Typography color="textSecondary" sx={{ mt: 2, mb: 1 }}>
                        Email: {collaborator.email || 'N/A'}
                      </Typography>
                      <Typography color="body2" sx={{ mt: 2, mb: 1 }}>
                        Contributions: {collaborator.contributions}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Shared Repositories: {collaborator.repositories.join(', ')}
                      </Typography>
                      <Button
                        variant="text"
                        href={collaborator.profile_url}
                        target="_blank"
                        startIcon={<Link />}
                      >
                        View Profile
                      </Button>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        )}

        {/* LinkedIn Results */}
        {results && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {/* Shared Connections Section */}
            {sharedConnections.length > 0 && (
              <>
                <Typography variant="h5" sx={{ mt: 6, mb: 1 }}>
                  Shared LinkedIn Connections
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                  These people are in your LinkedIn network and match your search criteria
                </Typography>
                <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(25, 118, 210, 0.08)', borderRadius: 2, border: '1px solid rgba(25, 118, 210, 0.2)' }}>
                  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Info sx={{ mr: 1, color: 'primary.main' }} />
                    To reach out to a reference, click the "Reach Out to Reference" button. This will send an email to the reference with a link to provide feedback online. No phone calls will be made.
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  {sharedConnections.map((vetter, index) => (
                    <Grid item xs={12} key={index}>
                      <StyledCard>
                        <StyledCardContent>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item>
                              <Avatar
                                sx={{
                                  width: 64,
                                  height: 64,
                                  bgcolor: 'gold',
                                }}
                              >
                                <Person />
                              </Avatar>
                            </Grid>
                            <Grid item xs>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h6" gutterBottom>
                                  {vetter.full_name}
                                </Typography>
                                <MuiTooltip title="This person is in your LinkedIn connections">
                                  <Star sx={{ ml: 1, color: 'gold' }} />
                                </MuiTooltip>
                              </Box>
                              <Typography color="text.secondary" gutterBottom>
                                {vetter.title} at {vetter.current_company.name}
                              </Typography>
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" color="textSecondary">
                                  Overlapped at: {vetter.overlap_company.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Period: {new Date(vetter.overlap_period.start).toLocaleDateString()} - {new Date(vetter.overlap_period.end).toLocaleDateString()}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item>
                              <ButtonGroup variant="outlined" color="primary">
                                <Button
                                  startIcon={<Link />}
                                  href={vetter.profile_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Profile
                                </Button>
                                <MuiTooltip title="Send an email to request a reference (no phone call)">
                                  <span>
                                    <Button
                                      startIcon={<Email />}
                                      onClick={() => handleSendReferenceEmail(
                                        vetter.public_identifier,
                                        vetter.full_name,
                                        vetter.email || 'example@example.com'
                                      )}
                                      disabled={emailSending[vetter.public_identifier] || emailSent[vetter.public_identifier]}
                                      color="secondary"
                                      variant="contained"
                                      sx={{ 
                                        fontWeight: 'bold',
                                        '&:not(:disabled)': {
                                          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)'
                                        }
                                      }}
                                    >
                                      {emailSending[vetter.public_identifier] ? (
                                        <CircularProgress size={20} color="inherit" />
                                      ) : emailSent[vetter.public_identifier] ? (
                                        'Email Sent'
                                      ) : (
                                        'Reach Out to Reference'
                                      )}
                                    </Button>
                                  </span>
                                </MuiTooltip>
                              </ButtonGroup>
                            </Grid>
                          </Grid>
                        </StyledCardContent>
                      </StyledCard>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {/* Regular Candidates Section */}
            <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(25, 118, 210, 0.08)', borderRadius: 2, border: '1px solid rgba(25, 118, 210, 0.2)' }}>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                <Info sx={{ mr: 1, color: 'primary.main' }} />
                To reach out to a reference, click the "Reach Out to Reference" button. This will send an email to the reference with a link to provide feedback online. No phone calls will be made.
              </Typography>
            </Box>
            <Typography variant="h5" sx={{ mt: 6, mb: 3 }}>
              {sharedConnections.length > 0 
                ? "Other Recommended Vetters" 
                : "Recommended Vetters"}
            </Typography>
            <Grid container spacing={3}>
              {otherCandidates.map((vetter, index) => (
                <Grid item xs={12} key={index}>
                  <StyledCard>
                    <StyledCardContent>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Avatar
                            sx={{
                              width: 64,
                              height: 64,
                              bgcolor: colors.primary,
                            }}
                          >
                            <Person />
                          </Avatar>
                        </Grid>
                        <Grid item xs>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                              {vetter.full_name}
                            </Typography>
                            {vetter.is_linkedin_connection && (
                              <MuiTooltip title="This person is in your LinkedIn connections">
                                <Star sx={{ ml: 1, color: 'gold' }} />
                              </MuiTooltip>
                            )}
                          </Box>
                          <Typography color="text.secondary" gutterBottom>
                            {vetter.title} at {vetter.current_company.name}
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" color="textSecondary">
                              Overlapped at: {vetter.overlap_company.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Period: {new Date(vetter.overlap_period.start).toLocaleDateString()} - {new Date(vetter.overlap_period.end).toLocaleDateString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ButtonGroup variant="outlined" color="primary">
                            <Button
                              startIcon={<Link />}
                              href={vetter.profile_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </Button>
                            <MuiTooltip title="Send an email to request a reference (no phone call)">
                              <span>
                                <Button
                                  startIcon={<Email />}
                                  onClick={() => handleSendReferenceEmail(
                                    vetter.public_identifier,
                                    vetter.full_name,
                                    vetter.email || 'example@example.com'
                                  )}
                                  disabled={emailSending[vetter.public_identifier] || emailSent[vetter.public_identifier]}
                                  color="secondary"
                                  variant="contained"
                                  sx={{ 
                                    fontWeight: 'bold',
                                    '&:not(:disabled)': {
                                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)'
                                    }
                                  }}
                                >
                                  {emailSending[vetter.public_identifier] ? (
                                    <CircularProgress size={20} color="inherit" />
                                  ) : emailSent[vetter.public_identifier] ? (
                                    'Email Sent'
                                  ) : (
                                    'Reach Out to Reference'
                                  )}
                                </Button>
                              </span>
                            </MuiTooltip>
                          </ButtonGroup>
                        </Grid>
                      </Grid>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        )}

        {success && (
          <Alert severity="success" sx={{ mt: 3 }}>
            {success}
          </Alert>
        )}

        {/* Transcripts Section */}
        <StyledPaper>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <History sx={{ mr: 1 }} />
            Reference Check Transcripts
          </Typography>
          
          {loadingTranscripts ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : transcripts.length > 0 ? (
            <List>
              {transcripts.map((transcript) => (
                <ListItem
                  key={transcript.call_sid}
                  divider
                  secondaryAction={
                    <Button
                      variant="outlined"
                      onClick={() => handleViewTranscript(transcript.call_sid)}
                      size="small"
                    >
                      View Transcript
                    </Button>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography>
                        Reference Check {transcript.reference_id ? `for ${transcript.reference_id}` : ''}
                      </Typography>
                    }
                    secondary={`Call Time: ${formatDate(transcript.created_at)}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
              No reference check transcripts available
            </Typography>
          )}
        </StyledPaper>

        {/* Transcript Dialog */}
        <TranscriptDialog 
          open={transcriptDialogOpen} 
          onClose={() => setTranscriptDialogOpen(false)} 
          transcript={selectedTranscript} 
        />
      </motion.div>
    </PageContainer>
  );
};

export default VettingPage;