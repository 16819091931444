import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

export const generateWeights = createAsyncThunk(
  'search/generateWeights',
  async (query, { rejectWithValue }) => {
    try {
      const response = await api.generateWeights(query);
      return response.weights;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const searchCandidates = createAsyncThunk(
  'search/searchCandidates',
  async ({ query, weights, listingId, minGpa, logForFinetuning }, { rejectWithValue }) => {
    try {
      const response = await api.searchProjects(query, weights, listingId, minGpa, logForFinetuning);
      
      console.log('Search API Response:', response);
      
      // Handle potential response issues
      if (!response) {
        return rejectWithValue('No response from server');
      }
      
      // Verify successful response
      if (!response.success) {
        return rejectWithValue(response.error || 'Search request failed');
      }

      // Ensure we have results array
      if (!Array.isArray(response.results)) {
        console.error('Results is not an array:', response.results);
        return rejectWithValue('Invalid results format from server');
      }

      return {
        results: response.results.map((result, index) => {
          if (!result) {
            console.warn(`Null or undefined result at index ${index}`);
            return null;
          }
          
          return {
            rank: index + 1,
            name: result.person_name || 'Unknown',
            score: Math.round((result.score || 0) * 100),
            match_reason: result.match_reason || '',
            education_level: result.education_level || '',
            education_details: result.education_details || '',
            undergrad_gpa: result.undergrad_gpa || 0.0,
            
            // Ensure experience data is properly mapped
            experience: Array.isArray(result.experience) 
              ? result.experience.map(exp => ({
                  title: exp.title || exp.position || '',
                  company_name: exp.company_name || exp.company || '',
                  date_range: exp.date_range || `${exp.start_date || ''} - ${exp.end_date || 'Present'}`,
                  description: exp.description || '',
                  location: exp.location || ''
                }))
              : [],
            
            // Ensure education data is properly mapped  
            education: Array.isArray(result.education)
              ? result.education.map(edu => ({
                  school_name: edu.school_name || edu.school || '',
                  degree_name: edu.degree_name || edu.degree || '',
                  field_of_study: edu.field_of_study || edu.field || '',
                  date_range: edu.date_range || `${edu.start_date || ''} - ${edu.end_date || ''}`,
                  grade: edu.grade || edu.gpa || ''
                }))
              : [],
            
            // Ensure skills are properly mapped
            skills: Array.isArray(result.skills) 
              ? result.skills 
              : (result.skills_list || []),
              
            // Latest position for summary display
            latest_position: result.latest_position || 
              (Array.isArray(result.experience) && result.experience.length > 0 
                ? { 
                    title: result.experience[0].title || result.experience[0].position || '',
                    company_name: result.experience[0].company_name || result.experience[0].company || ''
                  } 
                : null),
                
            projects: Array.isArray(result.projects) 
              ? result.projects.map(project => ({
                  project_name: project.project_name || '',
                  description: project.description || '',
                  match_reason: project.match_reason || '',
                  score: Math.round((project.score || 0) * 100),
                  company: project.company || ''  
                }))
              : []
          };
        }).filter(Boolean), // Remove any null results
        key_experiences: response.key_experiences || []
      };
    } catch (error) {
      console.error('Search error:', error);
      
      // Network errors
      if (error.message === 'Network Error') {
        return rejectWithValue('Cannot connect to server. Please check your internet connection.');
      }
      
      // Server errors (500 range)
      if (error.response?.status >= 500) {
        return rejectWithValue('Server error occurred. Our team has been notified.');
      }
      
      // Client errors (400 range)
      if (error.response?.status >= 400 && error.response?.status < 500) {
        const errorMessage = error.response?.data?.error || 'Invalid request parameters';
        return rejectWithValue(`Request error: ${errorMessage}`);
      }
      
      // Default error message
      return rejectWithValue(error.message || 'An error occurred during search');
    }
  }
);

export const processResume = createAsyncThunk(
  'search/processResume',
  async ({ file, githubUsername, namespace }, { rejectWithValue }) => {
    try {
      const response = await api.processResume(file, githubUsername, namespace);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    candidates: [],
    key_experiences: [],
    weightsLoading: false,
    searchLoading: false,
    uploadLoading: false,
    selectedIndex: 'all',
    error: null
  },
  reducers: {
    clearResults: (state) => {
      state.candidates = [];
      state.key_experiences = [];
      state.error = null;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateWeights.pending, (state) => {
        state.weightsLoading = true;
        state.error = null;
      })
      .addCase(generateWeights.fulfilled, (state, action) => {
        state.weightsLoading = false;
        state.key_experiences = action.payload;
      })
      .addCase(generateWeights.rejected, (state, action) => {
        state.weightsLoading = false;
        state.error = action.payload;
      })
      .addCase(searchCandidates.pending, (state) => {
        state.searchLoading = true;
        state.error = null;
      })
      .addCase(searchCandidates.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.candidates = action.payload.results;
        state.key_experiences = action.payload.key_experiences;
      })
      .addCase(searchCandidates.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.payload;
      })
      .addCase(processResume.pending, (state) => {
        state.uploadLoading = true;
        state.error = null;
      })
      .addCase(processResume.fulfilled, (state) => {
        state.uploadLoading = false;
      })
      .addCase(processResume.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearResults, setSelectedIndex } = searchSlice.actions;
export default searchSlice.reducer;