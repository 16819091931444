// File: src/components/Dashboard/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Grid,
  IconButton,
  Alert,
  AppBar,
  Toolbar,
  Slide,
  useMediaQuery,
  Chip,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import DOMPurify from 'dompurify';

// Icons - consolidate all MUI icons
import {
  Add as AddIcon,
  Search as SearchIcon,
  Upload as UploadIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  BarChart as BarChartIcon,
  CloudUpload as CloudUploadIcon,
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  Logout as LogoutIcon,
  Article as ArticleIcon,
  Work as WorkIcon
} from '@mui/icons-material';

// Stripe imports
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Local imports
import {
  fetchListings,
  initiateListingCreation,
  completeListingCreation,
  updateListing,
  deleteListing,
  clearPaymentIntent,
  validatePromoCode,
  clearPromoCode,
} from '../../services/listingSlice';
import { processResume } from '../../services/api';
import { logout } from '../../services/authSlice';
import DataInsights from './DataInsights';
import { CreditManager } from './CreditManager';
import { stripePromise } from './stripe';
import ATSIntegration from './ATSIntegration';
import PageContainer, { childVariants } from '../common/PageContainer';
import ContentCard from '../common/ContentCard';
import { motion } from 'framer-motion';
import { colors } from '../common/IntratalentTheme';

// ----------------------------------------------
// Styled Components
// ----------------------------------------------
const JobListingCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  position: 'relative',
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.05)',
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.3)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '10px',
  padding: '8px 16px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const PrimaryActionButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: 'rgba(41, 127, 255, 0.15)',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: 'rgba(41, 127, 255, 0.25)',
    transform: 'translateY(-2px)',
  },
}));

const SecondaryActionButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)',
  },
}));

const JobCount = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  fontWeight: 'bold',
  marginLeft: theme.spacing(1),
}));

const JobTag = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(41, 127, 255, 0.1)',
  color: theme.palette.primary.main,
  borderRadius: '8px',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------
// Utility Components
// ----------------------------------------------
const TruncatedDescription = ({ text, maxLines = 3 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize(text);

  return (
    <>
      <Box
        sx={{
          mb: 2,
          color: 'text.secondary',
          maxHeight: isExpanded ? '300px' : '200px',
          overflow: isExpanded ? 'auto' : 'hidden',
          position: 'relative',
          '& ul, & ol': {
            paddingLeft: '20px',
            margin: '8px 0',
          },
          '& li': {
            marginBottom: '4px',
          },
          '& p': {
            margin: '8px 0',
          },
          '& h3': {
            margin: '16px 0 8px 0',
            fontSize: '1.1rem',
            fontWeight: 'bold',
          },
          '& strong': {
            fontWeight: 'bold',
          },
          // Add custom scrollbar styling
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.3)',
            },
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        {!isExpanded && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '50px',
              background: 'linear-gradient(transparent, rgba(9, 9, 9, 0.9))',
            }}
          />
        )}
      </Box>
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ 
          textTransform: 'none', 
          fontSize: '14px',
          color: 'primary.main',
          '&:hover': {
            backgroundColor: 'rgba(41, 127, 255, 0.1)',
          }
        }}
      >
        {isExpanded ? 'Show Less' : 'Show More'}
      </Button>
    </>
  );
};

// ----------------------------------------------
// Payment Form for Creating Listing
// ----------------------------------------------
const PaymentForm = ({ onSuccess, onError, listingData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [promoCode, setPromoCode] = useState('');

  const {
    promoCodeStatus,
    promoCodeDiscount,
    error,
    amount
  } = useSelector((state) => state.listings);

  const baseAmount = 5000; // $50.00 in cents
  const currentAmount = amount || baseAmount;

  const handleValidatePromoCode = async () => {
    if (!promoCode.trim()) return;
    try {
      await dispatch(validatePromoCode(promoCode)).unwrap();
      await dispatch(
        initiateListingCreation({
          listingData: {
            title: listingData.title,
            job_description: listingData.job_description,
            namespace: listingData.namespace || 'all',
          },
          promoCode: promoCode,
        })
      ).unwrap();
    } catch (err) {
      console.error('Promo code validation error:', err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // If amount is 0, skip payment confirmation
    if (currentAmount === 0) {
      setProcessing(true);
      try {
        // Complete creation without payment
        await dispatch(
          completeListingCreation({
            paymentIntentId: 'free',  // Special case for free listings
            listingData: {
              title: listingData.title,
              job_description: listingData.job_description,
              namespace: listingData.namespace || 'all',
            },
          })
        ).unwrap();

        onSuccess();
      } catch (err) {
        console.error('Error creating free listing:', err);
        onError(err.message || 'An error occurred while creating the listing');
      } finally {
        setProcessing(false);
      }
      return;
    }

    if (!stripe || !elements) return;

    setProcessing(true);
    try {
      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (paymentError) {
        throw new Error(paymentError.message);
      }

      // Complete creation
      await dispatch(
        completeListingCreation({
          paymentIntentId: paymentIntent.id,
          listingData: {
            title: listingData.title,
            job_description: listingData.job_description,
            namespace: listingData.namespace || 'all',
          },
        })
      ).unwrap();

      onSuccess();
    } catch (err) {
      console.error('Payment Error:', err);
      onError(err.message || 'An error occurred during payment');
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearPromoCode());
    };
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          error={promoCodeStatus === 'invalid'}
          helperText={promoCodeStatus === 'invalid' ? error : ''}
          fullWidth
          sx={{ mb: 1 }}
        />
        <Button
          onClick={handleValidatePromoCode}
          disabled={!promoCode || processing || promoCodeStatus === 'validating'}
          variant="outlined"
          fullWidth
        >
          {promoCodeStatus === 'validating' ? 'Validating...' : 'Apply Promo Code'}
        </Button>

        <Box sx={{ mt: 2 }}>
          {promoCodeStatus === 'valid' && promoCodeDiscount && (
            <Alert severity="success" sx={{ mb: 1 }}>
              Promo code applied! You'll receive a{' '}
              {promoCodeDiscount.type === 'percentage'
                ? `${promoCodeDiscount.value}% discount`
                : `$${promoCodeDiscount.value / 100} discount`}
            </Alert>
          )}

          <Typography variant="body1" sx={{ textAlign: 'right' }}>
            Original price: ${(baseAmount / 100).toFixed(2)}
          </Typography>

          {promoCodeStatus === 'valid' && promoCodeDiscount && (
            <Typography variant="body1" color="success.main" sx={{ textAlign: 'right' }}>
              Discount: -${((baseAmount - currentAmount) / 100).toFixed(2)}
            </Typography>
          )}

          <Typography variant="h6" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            Total: ${(currentAmount / 100).toFixed(2)}
          </Typography>
        </Box>
      </Box>

      {/* Only show payment element if amount is not 0 */}
      {currentAmount > 0 ? (
        <PaymentElement />
      ) : (
        <Alert severity="success" sx={{ mb: 2 }}>
          Your promo code makes this listing free! Click submit to create your listing.
        </Alert>
      )}

      <Button
        type="submit"
        disabled={currentAmount > 0 ? !stripe || processing : processing}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      >
        {processing ? 'Processing...' : currentAmount === 0 ? 'Create Free Listing' : 'Pay and Create Listing'}
      </Button>
    </form>
  );
};

// ----------------------------------------------
// Dashboard Component
// ----------------------------------------------
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { companyName } = useSelector((state) => state.auth);
  const {
    list: listings,
    loading,
    error,
    paymentIntent,
    pendingListingData,
  } = useSelector((state) => state.listings);

  // Local state for dashboard
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listingToDelete, setListingToDelete] = useState(null);
  const [editingListing, setEditingListing] = useState(null);
  const [formData, setFormData] = useState({ title: '', job_description: '' });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedListingForUpload, setSelectedListingForUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const [githubUsername, setGithubUsername] = useState('');
  const [insightsDialogOpen, setInsightsDialogOpen] = useState(false);
  const [selectedListingForInsights, setSelectedListingForInsights] = useState(null);
  const [scoreDistribution, setScoreDistribution] = useState(null);

  // Fetch Listings on mount or when user logs in
  useEffect(() => {
    dispatch(fetchListings());
  }, [dispatch]);

  // Handlers
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditingListing(null);
    setFormData({ title: '', job_description: '' });
  };

  const handleEditListing = (listing) => {
    setEditingListing(listing);
    setFormData({
      title: listing.title,
      job_description: listing.job_description,
    });
    setDialogOpen(true);
  };

  const handleDeleteClick = (listing) => {
    setListingToDelete(listing);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    await dispatch(deleteListing(listingToDelete.id));
    setDeleteDialogOpen(false);
    setListingToDelete(null);
  };

  const validateForm = () => {
    if (!formData.title?.trim()) return false;
    if (!formData.job_description?.trim()) return false;
    if (formData.job_description.length < 50) return false;
    return true;
  };

  const handleSubmitListing = async () => {
    if (!validateForm()) {
      alert('Please complete the form. Job description should be at least 50 characters.');
      return;
    }

    if (editingListing) {
      await dispatch(updateListing({ id: editingListing.id, ...formData }));
      handleCloseDialog();
    } else {
      await dispatch(
        initiateListingCreation({
          listingData: {
            title: formData.title,
            job_description: formData.job_description,
            namespace: 'all',
          },
        })
      );
    }
  };

  const handleOpenInsights = (listing) => {
    setSelectedListingForInsights(listing);
    setInsightsDialogOpen(true);
  };

  const handleOpenUploadDialog = (listing) => {
    setSelectedListingForUpload(listing);
    setUploadDialogOpen(true);
    resetUploadForm();
  };

  const resetUploadForm = () => {
    setUploadFile(null);
    setUploadError(null);
    setUploadSuccess(false);
    setUploadResults(null);
    setGithubUsername('');
  };

  const handleCloseUploadDialog = () => {
    setUploadDialogOpen(false);
    setSelectedListingForUpload(null);
    resetUploadForm();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (file.type !== 'application/pdf' && file.name.split('.').pop() !== 'zip') {
      setUploadError('Only PDF or ZIP files are accepted.');
      return;
    }

    // Validate file size (10MB max)
    if (file.size > 10 * 1024 * 1024) {
      setUploadError('File is too large. Maximum size is 10MB.');
      return;
    }

    setUploadFile(file);
    setUploadError(null);
  };

  const handleSubmitUpload = async () => {
    if (!uploadFile) {
      setUploadError('Please select a file to upload.');
      return;
    }

    if (!selectedListingForUpload) {
      setUploadError('No listing selected for upload.');
      return;
    }

    setUploadLoading(true);
    setUploadError(null);
    setUploadSuccess(false);
    setUploadResults(null);

    try {
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('listingId', selectedListingForUpload.id);
      
      if (githubUsername && uploadFile.type === 'application/pdf') {
        formData.append('githubUsername', githubUsername);
      }

      const response = await processResume(formData);
      
      if (response.success) {
        setUploadSuccess(true);
        // If it's a ZIP file, it will have upload results with counts
        if (response.results) {
          setUploadResults(response.results);
        }
        
        // Fetch updated listings
        dispatch(fetchListings());
      } else {
        setUploadError(response.error || 'An error occurred during upload.');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError(error.message || 'Failed to upload file.');
    } finally {
      setUploadLoading(false);
    }
  };

  // --------------------------------------------------
  // RENDER
  // --------------------------------------------------
  return (
    <PageContainer>
      <motion.div variants={childVariants}>
        <Typography variant="h1" component="h1" gutterBottom>
          Dashboard
        </Typography>
        <Typography 
          variant="body1" 
          color="textSecondary" 
          textAlign="center" 
          sx={{ mb: 6, maxWidth: '700px', mx: 'auto' }}
        >
          Manage your job listings, upload resumes, and view insights.
        </Typography>
      </motion.div>

      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
        <Box>
          <Typography variant="h4" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
            <WorkIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            Job Listings
            <JobCount label={listings.length} />
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Manage and track your job postings in one place
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* <CreditManager /> */}
          <ATSIntegration />
          <PrimaryActionButton
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(true)}
          >
            Create New Listing
          </PrimaryActionButton>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Divider sx={{ mb: 4 }} />
      </Box>

      {listings.length === 0 ? (
        <ContentCard 
          centered
          tagIcon={<ArticleIcon sx={{ color: theme.palette.primary.main }} />}
          tagText="Get Started"
          title="Create Your First Job Listing"
        >
          <Typography color="textSecondary" sx={{ mb: 4, maxWidth: '600px' }}>
            To begin finding the perfect candidates, create a job listing with detailed requirements.
            Our AI will analyze and match the most relevant candidates for your position.
          </Typography>
          <PrimaryActionButton
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(true)}
            size="large"
          >
            Create Job Listing
          </PrimaryActionButton>
        </ContentCard>
      ) : (
        <Grid container spacing={3}>
          {listings.map((listing, index) => (
            <Grid item xs={12} md={6} key={listing?.id}>
              <JobListingCard
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {listing?.title}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
                    <JobTag label="Active" size="small" />
                    {listing.total_candidates > 0 && (
                      <JobTag 
                        label={`${listing.total_candidates} Candidates`} 
                        size="small" 
                      />
                    )}
                  </Box>
                </Box>
                
                <Box sx={{ flex: 1, overflow: 'hidden', mb: 3 }}>
                  <TruncatedDescription text={listing?.job_description} />
                </Box>
                
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 'auto' }}>
                  <PrimaryActionButton
                    startIcon={<SearchIcon />}
                    onClick={() => navigate(`/search/${listing.id}`, { state: { listing } })}
                  >
                    Search
                  </PrimaryActionButton>
                  
                  <SecondaryActionButton
                    startIcon={<UploadIcon />}
                    onClick={() => handleOpenUploadDialog(listing)}
                  >
                    Upload
                  </SecondaryActionButton>
                  
                  <SecondaryActionButton
                    startIcon={<BarChartIcon />}
                    onClick={() => handleOpenInsights(listing)}
                  >
                    Insights
                  </SecondaryActionButton>
                  
                  <Box sx={{ flexGrow: 1 }} />
                  
                  <IconButton
                    onClick={() => handleEditListing(listing)}
                    sx={{
                      color: theme.palette.primary.main,
                      backgroundColor: 'rgba(41, 127, 255, 0.1)',
                      '&:hover': {
                        backgroundColor: 'rgba(41, 127, 255, 0.2)',
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  
                  <IconButton
                    onClick={() => handleDeleteClick(listing)}
                    sx={{
                      color: theme.palette.error.main,
                      backgroundColor: 'rgba(220, 38, 38, 0.1)',
                      '&:hover': {
                        backgroundColor: 'rgba(220, 38, 38, 0.2)',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </JobListingCard>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Create/Edit Listing Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        fullWidth 
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: colors.black2,
            borderRadius: '20px',
          }
        }}
      >
        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          {editingListing ? 'Edit Job Listing' : 'Create New Job Listing'}
          <IconButton
            onClick={handleCloseDialog}
            sx={{ 
              position: 'absolute', 
              right: 8, 
              top: 8,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Job Title"
            fullWidth
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            sx={{ mb: 3 }}
          />
          <TextField
            label="Job Description"
            fullWidth
            multiline
            rows={8}
            value={formData.job_description}
            onChange={(e) => setFormData({ ...formData, job_description: e.target.value })}
            placeholder="Provide a detailed job description including requirements, responsibilities, and qualifications..."
            helperText={`${formData.job_description?.length || 0} characters (minimum 50)`}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button 
            onClick={handleCloseDialog}
            sx={{ 
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitListing}
            variant="contained"
            disabled={!validateForm()}
          >
            {editingListing ? 'Save Changes' : 'Continue to Payment'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Dialog (appears after 'Continue to Payment' if no editingListing) */}
      {paymentIntent && pendingListingData && (
        <Dialog
          open={true}
          onClose={() => dispatch(clearPaymentIntent())}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              backgroundColor: colors.black2,
              borderRadius: '20px',
            }
          }}
        >
          <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            Complete Payment
            <IconButton
              onClick={() => dispatch(clearPaymentIntent())}
              sx={{ 
                position: 'absolute', 
                right: 8, 
                top: 8,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ pt: 3 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Complete your payment to create your job listing and start finding candidates.
            </Typography>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent,
                appearance: { 
                  theme: 'night',
                  variables: {
                    colorPrimary: colors.blue,
                    colorBackground: colors.black3,
                    colorText: colors.white,
                    colorDanger: colors.error,
                    fontFamily: 'Plus Jakarta Sans, sans-serif',
                  }
                },
              }}
            >
              <PaymentForm
                onSuccess={() => {
                  dispatch(clearPaymentIntent());
                  handleCloseDialog();
                  dispatch(fetchListings());
                }}
                onError={(error) => {
                  console.error(error);
                }}
                listingData={pendingListingData}
              />
            </Elements>
          </DialogContent>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.black2,
            borderRadius: '20px',
          }
        }}
      >
        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          Confirm Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Are you sure you want to delete this job listing?
          </Typography>
          <Typography variant="body2" color="error" fontWeight="medium">
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ 
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Insights Dialog */}
      <Dialog
        open={insightsDialogOpen}
        onClose={() => setInsightsDialogOpen(false)}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        PaperProps={{
          sx: {
            backgroundColor: colors.black1,
          }
        }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: colors.black2 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setInsightsDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Insights for {selectedListingForInsights?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '100%', overflow: 'auto' }}>
          {selectedListingForInsights && (
            <DataInsights
              listing={selectedListingForInsights}
              scoreDistribution={scoreDistribution}
            />
          )}
        </Box>
      </Dialog>

      {/* Upload Resume Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={handleCloseUploadDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: colors.black2,
            borderRadius: '20px',
          }
        }}
      >
        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          Upload Resumes
          <IconButton
            onClick={handleCloseUploadDialog}
            sx={{ 
              position: 'absolute', 
              right: 8, 
              top: 8,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {selectedListingForUpload
              ? `Upload resumes for "${selectedListingForUpload.title}" to find matching candidates.`
              : 'Upload resumes to match with job listings.'}
          </Typography>

          <Box
            sx={{
              border: `2px dashed ${theme.palette.divider}`,
              borderRadius: '16px',
              p: 3,
              mb: 3,
              textAlign: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                borderColor: theme.palette.primary.main,
              },
            }}
            onClick={() => document.getElementById('resume-upload').click()}
          >
            <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              {uploadFile ? 'Replace File' : 'Select File'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Click to browse or drag & drop PDF or ZIP files here
            </Typography>
            <input
              id="resume-upload"
              type="file"
              accept=".pdf,.zip"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {uploadFile && (
              <Chip 
                label={uploadFile.name}
                onDelete={() => setUploadFile(null)}
                color="primary"
                variant="outlined"
                sx={{ mt: 2 }}
              />
            )}
          </Box>

          {/* GITHUB Username (only relevant for single PDFs) */}
          {uploadFile?.type === 'application/pdf' && (
            <TextField
              fullWidth
              label="GitHub Username (Optional)"
              variant="outlined"
              value={githubUsername}
              onChange={(e) => setGithubUsername(e.target.value)}
              sx={{ mb: 3 }}
              placeholder="Enter GitHub username to link with this resume"
            />
          )}

          {/* Upload Error/Success */}
          {uploadError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {uploadError}
            </Alert>
          )}
          {uploadSuccess && !uploadResults && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Resume uploaded successfully!
            </Alert>
          )}
          {/* If multiple resumes (ZIP) have results */}
          {uploadResults && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert
                severity={uploadResults.failed_uploads > 0 ? 'warning' : 'success'}
                sx={{ mb: 2 }}
              >
                {`Processed ${uploadResults.total_files} files: ${uploadResults.successful_uploads} successful, ${uploadResults.failed_uploads} failed`}
              </Alert>
              {uploadResults.failed_uploads > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="subtitle2" color="error" sx={{ mb: 1 }}>
                    Failed Uploads:
                  </Typography>
                  {uploadResults.errors.map((error, index) => (
                    <Typography key={index} variant="body2" color="error">
                      {`${error.file_name}: ${error.error}`}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button 
            onClick={handleCloseUploadDialog}
            sx={{ 
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitUpload}
            variant="contained"
            disabled={uploadLoading || !uploadFile}
            startIcon={uploadLoading ? <CircularProgress size={18} color="inherit" /> : null}
          >
            {uploadLoading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Dashboard;